import { ee } from "../../services/earth-engine";
import { Layer } from "../../common/classes";
import { generateVisualizationParams } from "../utils";
//import { ConditionalNodeDependencies } from "mathjs";

/*
 * Given a {masked} image having {bandName} with pixel values {0, 1} where 1 indicates
 * cloud presence, the function reduces the region specified in {geometry}
 * with params {scale} and {maxPixels} adding pixel values and then divides
 * it by the pixel count to get the cloud ratio.
 */
export const scoreCloudRatio = (
  masked,
  bandName,
  geometry,
  scale = 30,
  maxPixels = 1e12
) => {
  const cloudyArea = masked.multiply(ee.Image.pixelArea());

  const cloudClassSum = cloudyArea.reduceRegion({
    reducer: ee.Reducer.sum(),
    scale: scale,
    maxPixels: maxPixels,
    geometry: geometry,
  });

  return ee.Number(cloudClassSum.get(bandName)).divide(geometry.area());
};


export const generateLayer = (image, mission, name, params) => {
  if (params === undefined) {
    console.log("params is undefined", params);
    params = generateVisualizationParams(mission);
  }
  //console.log("Generating layer (imagery/index.js)",image,image.getInfo(), name, params );
  return new Layer(image, name, params);
};

export const createThumbnail = (image, geometry, params, callback) => {
  //code for LANDSAT COLLECTION 2
  let satellite = window.sessionStorage.getItem("selectedSatellite");
  if (satellite === "Landsat") {
    //if(image.getInfo().bands[0].id.indexOf("SR") != -1){
    // Applies scaling factors.
    function applyScaleFactors(image) {
      var opticalBands = image.select('SR_B.').multiply(0.0000275).add(-0.2);
      var thermalBands = image.select('ST_B.*').multiply(0.00341802).add(149.0);
      return image.addBands(opticalBands, null, true).addBands(thermalBands, null, true);
    }
    // image = applyScaleFactors(image);
    console.log("bands thumbnail", params.bands, "image", image, image.getInfo());
    var visualization = {
      bands: params.bands,//['SR_B4', 'SR_B3', 'SR_B2']
      min: 0.0,
      max: 0.4, //0.3
    };
    console.log("IMG::", "param", params, "bands", params.bands, image.getInfo(), "Id()", image.id(), "Id().getInfo()", image.id, image.id().getInfo(), "properties", image.propertyNames().getInfo());
    //LANDSAT- 5 (3,2,1)
    var outImg = image.visualize(visualization);
    //console.log("createThumbnail", "visualization", outImg.getThumbURL({region: geometry,dimensions: 512, format: 'png'}))
    return outImg.getThumbURL({ region: geometry, dimensions: 512, format: 'png' }, callback);
  } else {
    //generic code (LANDSAT COL1, SENTINEL)
    const generationParams = {
      region: geometry,
      format: "jpg",
      dimensions: 512,
      ...params,
    };
    /*
    if (satellite === "Sentinel-2") {
      let dt = new Date(image.getInfo().properties["system:time_start"].value);
      let dtThreshold = new Date(2022, 1, 1);// 01.02.2022
      if (dt > dtThreshold) { 
     //   generationParams.max = 4000;
      //  generationParams.min = 1000;
        return image.getThumbURL(generationParams, callback);
      }
    }
    */
    return image.getThumbURL(generationParams, callback);
  }
};

export const createThumbnailCSqueeze = (image, geometry, params, callback) => {
  console.log("CREATING CSQUEEZE THUMBNAIL", params, geometry)
  //code for LANDSAT COLLECTION 2
  let satellite = window.sessionStorage.getItem("selectedSatellite");
  if (satellite === "Landsat") {
    //if(image.getInfo().bands[0].id.indexOf("SR") != -1){
    // Applies scaling factors.
    function applyScaleFactors(image) {
      var opticalBands = image.select('SR_B.').multiply(0.0000275).add(-0.2);
      var thermalBands = image.select('ST_B.*').multiply(0.00341802).add(149.0);
      return image.addBands(opticalBands, null, true).addBands(thermalBands, null, true);
    }
    image = applyScaleFactors(image);
    console.log("bands thumbnail", params.bands, "image", image, image.getInfo());
    var visualization = {
      bands: params.bands,//['SR_B4', 'SR_B3', 'SR_B2']
      min: 0.0,
      max: 0.3, //0.3
    };
    // console.log("IMG::", "param", params, "bands", params.bands, image.getInfo(), "Id()", image.id(), "Id().getInfo()", image.id, image.id().getInfo(), "properties", image.propertyNames().getInfo());
    //LANDSAT- 5 (3,2,1)
    var outImg = image.visualize(visualization);
    //console.log("createThumbnail", "visualization", outImg.getThumbURL({region: geometry,dimensions: 512, format: 'png'}))
    return outImg.getThumbURL({ region: geometry, dimensions: 512, format: 'png' }, callback);
  } else {
    //generic code (LANDSAT COL1, SENTINEL)
    const generationParams = {
      region: geometry,
      format: "jpg",
      dimensions: 512,
      ...params,
    };
    // if (satellite === "Sentinel-2") {

    //   let dt = new Date(image.getInfo().properties["system:time_start"].value);
    //   let dtThreshold = new Date(2022, 1, 1);// 01.02.2022
    //   if (dt > dtThreshold) {
    //     generationParams.max = 4000;
    //     generationParams.min = 1000;
    //     return image.getThumbURL(generationParams, callback);
    //   }
    // }
    return image.getThumbURL(generationParams, callback);
  }
};

/**
 * Calculates the cumulative window of the *fullHistogram*
 * by removing up to *percentile* pixels in each side of
 * the histogram's buckets.
 * @param {ee.Dictionary} fullHistogram
 * @param {Number|ee.Number} percentile
 * @returns {ee.List} the cumulative window bounds (min/max)
 */
export const cumulativeWindow = (fullHistogram, percentile) => {
  /* Cast to dictionary and retrieve histogram and buckets */
  const cast = ee.Dictionary(fullHistogram);
  const histogram = ee.List(cast.get("histogram"));
  const buckets = ee.List(cast.get("bucketMeans"));

  const pixelCount = ee.Number(histogram.reduce(ee.Reducer.sum()));
  const cumulativeCut = pixelCount.multiply(percentile);

  /*
   * lowerBound returns the min level where cumulative sum
   * would have exceeded the percentile threshold
   */
  const lowerBound = (histogram, buckets) => {
    const cumulativeMin = ee.List.sequence(
      0,
      buckets.size().subtract(1)
    ).iterate(
      (idx, acc) => {
        const ctx = ee.Dictionary(acc);

        const sum = ctx.getNumber("sum").add(histogram.getNumber(idx));
        const min = ee.Algorithms.If(
          sum.gt(cumulativeCut),
          ctx.getNumber("min"),
          buckets.getNumber(idx)
        );

        return ee.Dictionary({ min, sum });
      },
      { min: buckets.getNumber(0), sum: 0 }
    );

    return ee.Dictionary(cumulativeMin).getNumber("min");
  };

  /* Calculate the bound of each side */
  const lower = lowerBound(histogram, buckets);
  const upper = lowerBound(histogram.reverse(), buckets.reverse());

  return ee.List([lower, upper]);
};
