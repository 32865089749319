import { all, select, put } from "redux-saga/effects";
import i18n from "i18next";
import {
  concurrentHandler,
  bufferedHandler,
  callback,
  evaluate,
} from "../../tools/effects";
import { generateLayer, createThumbnail, createThumbnailCSqueeze } from "../../../algorithms/imagery";
import { generateVisualizationParams } from "../../../algorithms/utils";
import { aggregateMissionsDates } from "../../../common/algorithms";
import { Types, Actions } from "./header";
import { Actions as Imagery } from "../imagery";
import {
  getAcquisitionParameters,
  getImageryIdentifiers,
} from "../../../selectors";
import { ee } from "../../../services/earth-engine";
import { BathymetryResults } from "../../../app/pages/ProcessingBathymetryPage";
import { acquireFromDate } from "../../../algorithms/satellite/sentinel";

const handleLoadAvailableImages = function* () {
  const { satellite, geometry } = yield select(getAcquisitionParameters);

  const missions = satellite.missions;
  let availableByMission = [];

  for (let mission of missions) {
    try {
      let regionData = {
        name: mission.name,
        shortname: mission.shortname,
        dates: yield evaluate(mission.algorithms.queryAvailable(geometry)),
      };
      if (regionData.dates === null) {
        console.log("regionData.dates is null");
        continue;
      }
      if (Object.keys(regionData.dates).length === 0 && mission.fallbackMission) {
        yield put(Actions.setMissionFallback(mission));

        const fallback = mission.fallbackMission;

        regionData = {
          name: fallback.name,
          shortname: fallback.shortname,
          dates: yield evaluate(fallback.algorithms.queryAvailable(geometry)),
        };
      }
      availableByMission.push(regionData);
    } catch (e) {
      console.log("Error", mission, e);
    }
  }

  const availableDates = aggregateMissionsDates(availableByMission);

  if (availableDates !== undefined) {
    yield put(Actions.setAvailableDates(availableDates, availableByMission));
  }
};

const handleAcquireImage = function* ({ payload: { missionName, date } }) {
  const { geometry, satellite } = yield select(getAcquisitionParameters);
  const { imageId } = yield select(getImageryIdentifiers);

  const mission = satellite.get(missionName);
  //let image = mission.algorithms.acquire(date, geometry);

  let dtEnd = new Date().toISOString();
  console.log("ImageId", imageId, "Mission name", "date", date, "dateend:", dtEnd, missionName, "satellite", mission);

  let image = null;
  if (BathymetryResults.isBathymetry()) {
    console.log("aquiring from date...");
    image = acquireFromDate(BathymetryResults.baseImageDate, mission, geometry);
    console.log("Image from date acquired", image);
  } else {
    image = ee.ImageCollection(missionName).filterBounds(geometry).filter(ee.Filter.date(date, dtEnd)).first();
    let missionInfo = missionName.split("/");

    if (missionInfo[0] === "LANDSAT") {
      function applyScaleFactors(img) {
        var opticalBands = img.select('SR_B.').multiply(0.0000275).add(-0.2);
        var thermalBands = img.select('ST_B.*').multiply(0.00341802).add(149.0);
        return img.addBands(opticalBands, null, true).addBands(thermalBands, null, true);
      }
      //image = applyScaleFactors(image);  
      let bands = [mission.bands.red, mission.bands.green, mission.bands.blue];
      let visualization = {
        bands: bands,
        min: 0.0,
        max: 0.4,
      };
      image = image.visualize(visualization);
      image = image.rename(bands).clip(geometry);
    }
  }

  const layer = generateLayer(
    image,
    mission,
    i18n.t("forms.imageryOverlay.base")
  );
  console.log("push Image", date);
  yield put(
    Imagery.pushImage(mission.shortname + "/" + date, date, mission.name)
  );

  yield put(Imagery.loadLayer(layer, imageId));
};

const handleLoadThumbnails = function* () {
  
  let { geometry, satellite, availableDates } = yield select(
    getAcquisitionParameters
  );
  console.log("availableDates(handleLoadThumbnails) original (19.03)", availableDates);
  let id = 0; 
  for (const entry of availableDates) {
    const mission = satellite.get(entry.name);
    console.log("MISSION", mission);
    console.log("Thumbnail img for", entry.id); 

    let url = null;
    let clouds = null;
   // let imgCache = window.sessionStorage.getItem(entry.id+"_thumbnail");
    //if(imgCache === null){
        const image = mission.algorithms.acquire(entry.date, geometry);
        const visParam =  generateVisualizationParams(mission);
        url = yield callback(
          window.location.href.indexOf("csqueeze") === -1 ? createThumbnail : createThumbnailCSqueeze,
          image,
          geometry,
          visParam
        );
        //console.log("url", url,"visParam", visParam); 
        clouds = yield evaluate(image.get("CLOUDS")); 
        window.sessionStorage.setItem(entry.id+"_thumbnail",JSON.stringify({url, clouds}));  
   //}else{
     // imgCache = JSON.parse(imgCache);
     // url = imgCache.url; 
     // clouds = imgCache.clouds;
   // }
    yield put(
      Actions.insertMetadata(entry.name, entry.date, {
        id: id++,
        thumbnail: url,
        clouds: clouds,
      })
    );
    console.log("entry after load thumbnail", entry);
  }
};

const root = function* () {
  yield all([
    concurrentHandler(Types.LOAD_AVAILABLE_IMAGES, handleLoadAvailableImages),
    bufferedHandler(Types.ACQUIRE_IMAGE, handleAcquireImage),
    concurrentHandler(Types.LOAD_THUMBNAILS, handleLoadThumbnails),
  ]);
};

export default root;
