const en = {
  translation: {
    self: {
      title: "CASSIE",
      fullTitle: "Coastal Analyst System from Space Imagery Engine",
      shortDesc:
        "Collaborative web platform with geospatial observation and forecasting solutions applied to monitoring, mitigation and adaptation of the coastal zone in the face of climate change.",
      abstract:
        "The general objective of this project is to define technical and operational methodology and develop a web platform that provides information services derived from satellite and artificial intelligence (e.g. coastline, topography, bathymetry, coastal compression, hydrodynamics) to create collections applied to mitigation and adaptation of the coastal zone to climate change.",
      poweredBy: "Powered by: Google Earth Engine",
      imageryProvider: "Imagery Providers: NASA (Landsat) and ESA (Sentinel)",
      changeLanguage: "Language",
    },
    home: {
      about: {
        title: "About CASSIE CoRe",
      },
      instructions: {
        title: "CASSIE User Guide",
        text: "To use CASSIE, you must have a google account registered on the google earth engine platform.",
        btnEngineSingUp: "First access",
        btnManual: "User Guide",
        linkManual: "https://docs.google.com/document/d/1mu6HYv6U2gjoczXir3XGwQFgKu9jc00e/edit?usp=sharing&ouid=116796451018747305794&rtpof=true&sd=true",
        btnVideo: "Instruction Video",
        linkVideo: "https://www.youtube.com/@CASSIE-Core",
      },
      baysqueeze: {
        title: "BaySqueeze",
        text: 'The project seeks to contribute with actions and results for the preservation of our planet, by assuming the task of fulfilling the United Nations Sustainable Development Goals, as well as attending the national needs of knowledge about climate change, in parallel with the goals of the sub-network "Coastal Zones", of the Brazilian Research Network on Global Climate Change (Rede Clima). ',
        btn: "Know more about the project",
      },
      riscport: {
        title: "RiskPorts",
        text: "The main goal of this project is to conduct a climate risk analysis (environmental and economic) and to determine possible adaptation measures, based on the future projection of environmental threats (e.g. storm waves), to increase the resilience of the port sector and adjacent areas in the Babitonga Bay, in an interdisciplinary and multi-institutional network context.",
        btn: "Know more about the project",
      },
      members: {
        title: "Team",
        roles: {
          researcher: "Researcher",
          coord: "Coordinator",
          coord_geral: "General Coordinator",
          atp: "Grant holder ATP-B",
          bolsista: "Scholarship holder - Development",
          validacao: "Validation",
          desenvolvimento: "Development",
          fumdes: "Grant holder FUMDES",
          capes: "CAPES DTI-B Fellow",
        },
      },
      papers: {
        title: "Publications",
        resumo_text: "Abstract in Proceedings",
        paper_text: "Paper in Journal",
        curso_text: "Workshop",
        more: "Learn More",
      },
      sponsor: {
        realiza: "Developed by",
        apoio: "Financial Support",
        apoio_desc: "Current Process No. 406603/2022-7. Previous: Process No. 441818/2020-0, CNPq MCTIC/CNPq - N° 21/2017; Process No. 441545/2017-3.",
        inst: "Institutional support",
        partners: "Project Partners",
      },
      footer: {
        social: "Social",
        copyright: "All rights reserved. System by",
        help: {
          title: "Help & Support",
          faq: "FAQ",
          discussion: "Discussions",
          problems: "Known Issues",
        },
        contact: {
          title: "Contact",
          manage: "Project Management",
          techquest: "Technical Questions",
        },
        resources: {
          title: "Resources",
          license: "License",
          code: "Source Code",
        },
      },
      warning: {
        text: "CASSIE is still under development.",
        link: "See more",
      },
      cookies: {
        text: "Our website uses cookies to improve navigation.",
        link: "Ok",
      },
    },
    faq: {
      title: "Frequently Asked Questions",
      0: {
        title: "How does CASSIE works?",
        text: 'CASSIE uses Google Earth Engine (GEE), a platform of parallel processing in geospatial data cloud (<a href="https://www.sciencedirect.com/science/article/pii/S0034425717302900" target="_blank">Gorelick et al., 2017</a>). CASSIE communicates with the GEE through an API (Application Programming Interface), which allows it to use the datasets and processing potential of the GEE.',
      },
      1: {
        title: "What satellite image datasets are available at CASSIE?",
        text: 'Currently, CASSIE enables analysis with data from <a href="https://developers.google.com/earth-engine/datasets/catalog/COPERNICUS_S2" target="_blank">Sentinel 2 (MSI, TOA) Level-1C</a> and the Landsat collections (<a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LT05_C01_T1_SR" target="_blank">Landsat 5 TM SR Tier 1</a>, <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LE07_C01_T1_SR" target="_blank">Landsat 7 ETM+ SR Tier 1</a>, <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LC08_C01_T1_SR" target="_blank">Landsat 8 OLI SR Tier 1</a>). When Tier 1 images (higher quality) aren\'t available for Landsat 7 and 8, CASSIE uses images from the <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LE07_C01_T2_TOA" target="_blank">Landsat 7 TOA Tier 2</a> and <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LC08_C01_T2_TOA" target="_blank">Landsat 8 TOA Tier 2</a>.',
      },
      2: {
        title: "How do I access CASSIE?",
        text: 'CASSIE is available as an open source tool, available at <a href="http://cassie-stable.herokuapp.com/">this link</a>. The user needs a <a href="https://myaccount.google.com">Google account</a> registered (and approved) on <a href="https://signup.earthengine.google.com">Google Earth Engine plataform</a>. CASSIE\'s code is available at <a href="https://github.com/lia-univali/cassie">LIA\'s Github - Univali.',
      },
      3: {
        title:
          "When I sign in with my Google Account, the page loads up indefinitely",
        text: 'To access and use CASSIE, the user account must be registered on the Google Earth Engine (GEE) platform. The registration can be done through this <a href="https://earthengine.google.com/signup/" target="_blank">link</a>. Once the registration is confirmed, the user can access and sign in to CASSIE with your Google account.',
      },
      4: {
        title:
          "My account is registered in Google Earth Engine, but I still can't access the tool",
        text: 'Registration on the Google Earth Engine platform is terminated with a confirmation received via email. In some cases, the confirmation email is received instantly after the registration is made. However, confirmation is not always instantaneous and can take time that the CASSIE team has no control over. If the user has made the registration and has not yet received the confirmation email, and considers that the waiting time is too long, the user should contact the <a href="https://developers.google.com/earth-engine/help">Google Earth Engine team</a>. If the user cannot enter even after confirmation, it is recommended to <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">clear the browser cookies.',
      },
    },
    problems: {
      title: "Known Issues",
      text: "CASSIE is still under development, some processes may fail. These are some known issues.",
      0: {
        title: "BigData Analysis ",
        text: "Sometimes CASSIE Can't perform large analyses, with large region or with many images.",
      },
    },
    general: {
      loading: "Loading...",
      cancel: "Cancel",
    },
    auth: {
      signin: "Sign in with Google",
      signout: "Sign out",
      loading: "Authenticating...",
    },
    tour: {
      done: "Done",
      of: "of",
      help: "Help",
      acquisition: {
        1: {
          info: "In this Step you must choose the Satellite",
          "Sentinel-2": {
            info: "Sentinel has higher spatial resolution and less data acquisition time (from 2013-2021)",
          },
          Landsat: {
            info: "Landsat has lower spatial resolution and longer data acquisition time (from 1984 to 2021)",
          },
        },
        2: {
          info: "Select the interest area for analysis",
        },
        3: {
          period: "Select a date range to review",
          point: "Cada Ponto rosa é uma imagem",
          cloud: "Select an acceptable percentage of clouds in the image",
        },
        4: {
          id: "The ID contains important information such as the date and time the image was captured",
          clouds:
            "In this column we have calculated percentage of Clouds in the image",
          image: "This column shows the image in question",
          action:
            "In this last column, you can choose whether or not to include this image in the analysis",
        },
      },
      csqueeze: {
        2: {
          info: "",
          aoi: "Select the area of interest for analysis",
          mangrove: "This button allows you to visualize possible mangrove areas around the world using the \"ESA/WorldCover/v200\" collection",
          importKml: "It is also possible to import data from a KML file to draw the area of interest on the map."
        },
        3: {
          info: "",
          years: "Set two different years to generate the prediction study."
        },
        4: {
          info: "",
          years: "For each year, images with the lowest possible number of clouds should be chosen.",
          timeselector: "The pink dots represent the available images for the year with the selected cloud filter.",
          viewImageButton: "After selecting the pink dot with the available image, click here to view it. If nothing happens, it is possible that the image is 100% covered by clouds. This can happen because the level of clouds is defined by the satellite collection and not all regions are accurate.",
          cloudselector: "In this selection, it is possible to define the percentage of clouds in the images. When changing this parameter, the bar with pink dots will be updated, leaving only images that fit the value.",
          tableImages: "The selected images will be shown in this table. It is necessary to select at least 1 image for each year to continue."
        },
        5: {
          info: "",
          selectType: "Select all types to activate the drawing mode. When choosing the ground cover, click on the map to start drawing a rectangle in the region that represents that type. To draw the rectangle made, hold down CTRL and click on it or activate the \"Remove polygon\" button and click on the polygon. It is necessary to draw at least 1 mangrove polygon and another distinct polygon to continue.",
          falseColorButton: "If the image is not very clear, it is possible to activate the false color mask that uses the infrared band instead of the red band on the map.",
          availableDataset: "Datasets are ready-made collections mapped by users or organizations with ready-made data. If the desired year has an available dataset, it will be notified in the corner of the screen.",
          importPolygons: "It is also possible to import rectangles with a CSV file generated by the EXPORT button below.",
          generateRaster: "Exporting and clearing all rectangles is one of the available options. If you have already drawn at least 1 mangrove and another distinct rectangle, the \"GENERATE RASTER\" button will appear.",
          yearsButtons: "Don't forget to generate the rasters for both years to continue."
        }
      },
      map: {
        start: "This is the processing screen",
        imageChooser: {
          info: "In this field you can load on the map one of the selected images",
          select:
            "By default, the selected image is the most recent, but you can choose another one by clicking here",
          click:
            "After selecting the Image, you can load it on the screen by pressing this button",
        },
      },
    },
    forms: {
      intertidal:{
        title: "Interdal",
        next: "Continue",
        1:{
          title:"Choose satellite",
          description:"Select one of the available satellites for image acquisition."
        },
        2:{
          title:"Set input data",
          description:"Define the area of interest and the tidal data file to continue."
        },
        3:{
          title:"Image collection",
          description:"Choose the period of interest and then filter the images for the collection."
        },
        4:{
          title:"Intertidal area",
          description:"Refine the intertidal area or accept automatic.",
          returnAndRedo: "Recreate"
        },
        5:{
          title:"Step 5 title",
          description:"Step 5 desc"
        },
      },
      algorithm: {
        title: "Module Selection",
        csqueeze: "Coastal Squeeze",
        csqueezeDesc:
          "Mapping and analysis of indicators to obtain the Coastal Squeeze potential in mangrove areas.",
        bathymetry: "Bathymetry",
        bathymetryDesc:
          "Estimation of shallow water depth on a selected coastal region, using optical inversion algorithms and machine learning.",
        shoreline: "Shoreline Analyst",
        shorelineDesc:
          "Automatic mapping and statistical analysis of the shoreline evolution on any coastal area of interest in the world, using this module.",
          intermare: "Intertidal (under development)",
          intermareDesc: "Depth estimation on intertidal zones, through waterline method.",
         
        },
      acquisition: {
        title: "Image Acquisition",
        prev: "Return",
        next: "Continue",
        1: {
          title: "Choose satellite",
          description:
            "Select one of the available satellites for image acquisition.",
          card: {
            opticalResolution: "Optical Resolution",
            opticalResolutionUnit: "meters",
            activityPeriod: "Activity Period",
            provider: "Provider",
            revisitTime: "Revisit time",
            revisitTimeUnit: "days",
            choose: "Choose",
          },
        },
        2: {
          title: "Set region of interest",
          description: "Draw the region of interest using the map below.",
          showDelimiters: "Show delimiters",
          undo: "Undo",
          processFile: "Process file",
          importLegend: "Import the area of interest from a KML file:",
          importTitle: "KML import",
          example: "Examples may be downloaded here:",
          dataTrainning: "Data Training",
          dataTrainningLegend: "Import training data",
          dataTrainningFileLabel: "Please inform the date of tranning file:",
          dataTrainningDateHint: "*Use file name ending with _ddmmyyyy.csv to have the training date field automatically filled.",
          bathymetryDescription: "Please define the Area of Interest and the trainning data file to continue.",
          labelBathymetryStep: "Select the area of interest and the trainning data",
          helpCoordinateSystem: "By default CASSIE utilizes the EPSG:4326 coordinates system.\nBut in case your tranning file is based on another EPSG coordinates system,\nyou may type the coordinates system code of your file that CASSIE will interpret your data.",
          checkboxIntertidal: "Possible tide areas",
          intertidalArea: "Area",
          intertidalPeriod: "Period",
          intertidalTideData: "Tide data",
          intertidalTideImportKmz: "Import kmz",
          intertidalDrawOnMap: "Draw on map",
          intertidalDrawOnMapDetails: "Use the buttons on the map to define the area of interest.",
          intertidalTideImportData: "Import data",
          intertidalTideExtractData: "Extract data from model",
          intertidalPeriodSelection: "Define the date period for image collection",
          intertidalPeriodStartDate: "Select the analysis period start date",
          intertidalPeriodEndDate:  "Select the analysis period end date",
          intertidalCloudFilter: "Define the maximum cloud coverage",
          intertidalConfirmPeriod: "Confirm period",
          intertidalSelectAreaTitle: "Choose one of the following options to define the area",
          intertidalSelectTideTitle: "Choose one of the following options to define the tide data",
        },
        3: {
          title: "Set period",
          description: "Use the time slider to specify the period of analysis.",
          loading: "Loading...",
          period: "Period",
          to: "to",
          durationDays: {
            singular: "day",
            plural: "days",
          },
          durationMonths: {
            singular: "month",
            plural: "months",
          },
          durationYears: {
            singular: "year",
            plural: "years",
          },
          imageQuantity: {
            singular: "image",
            plural: "images",
          },
          cloudPercentage: "Cloud percentage",
          labelBathymetryStep: "Image selection",
          contentBathymetryStep: "Please select the image to continue.",
          bathymetry: {
            description: "Specify tyhe image to be utilized:",
            imageDate: "Data da imagem",
            dataDate: "Data dos dados in-situ",
            downloadImage: "Download image",
            intervalSelection: "Click here to select a interval for median image.",
            medianImagem: "Median image",
            intervalDefinition: "Interval Definition",
          }
        },
        4: {
          title: "Filter images",
          description: "Filter images to keep the appropriate images only.",
          table: {
            id: "ID",
            cloud: "Clouds",
            thumbnail: "Thumbnail",
            selected: "Selected",
          },
          imagesPerPage: "Images per page",
          to: "to",
          of: "of",
          labelBathymetryStep: "Bands, algorithm and threshold selection",
          contentBathymetryStep: "Please select the bands, the algorithm and the threshold to be applied",
          next: "Complete",
          bathymetry: {
            algorithmSelection: "Select an algorithm",
            algorithmSelectionHint: "Click over a chart to select an algorithm. The selected one become with red borders.",
            thresholdSelection: "Light extinction threshold (meters)",
            algorithmName1: "Band ratio",
            bandsSelection: "Bands selection", 
            updateCharts: "Update charts",
            band: "Band",
          }
        },
        5: {
          bathymetry: {
            bathymetry: "Bathymetry",
            loadStatistics: "Click here to load statistics",
            returnToBegin: "Return to module selection",
            meters: "meters",
            minDepth: "Minimum Predicted Depth",
            maxDepth: "Maximum Predicted Depth",
            processing: "Processing...<br />It may take a few seconds depending the tranning data size.",
            downloadStatistics: "Download statistics",
          }
        }

      },
      map: {
        roi: "Area of interest",
        baseline: "Baseline",
        shorelines: "Shorelines",
        transects: {
          title: "Transects",
          stable: "Stable",
          accreted: "Accreted",
          eroded: "Eroded",
          criticallyEroded: "Critically Eroded",
        },
        item: {
          s: "item",
          p: "items",
        },
        cancel: "Cancel",
      },
      imageChooser: {
        title: "Available Imagery",
        resultQuantity: "results",
        image: "Image",
        load: "Load",
        actions: {
          title: "Actions",
          analyzeShoreline: {
            title: "Analyze Shoreline",
            imageSelection: {
              title: "Select imagery",
              cancel: "Cancel",
              confirm: "Confirm",
            },
            baselineDraw: "Draw baseline",
          },
        },
      },
      shorelineParameters: {
        title: "Input parameters",
        description:
          "Define the transect spacing and extension, in meters, and input the threshold for land-water segmentation (0 = to automatic detection using Otsu thresholding method, -1 = automatic detection using multi-Otsu thresholding method",
        spacing: "Spacing (m)",
        extension: "Extension (m)",
        threshold: "Threshold",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      shorelineAnalysis: {
        title: "Shoreline Analysis",
        transectsReport: {
          title: "Transects Report",
          headers: {
            id: "ID",
            initialLatitude: "Latitude Begin",
            initialLongitude: "Longitude Begin",
            finalLatitude: "Latitude End",
            finalLongitude: "Longitude End",
            initialDate: "Date Begin",
            finalDate: "Date End",
            intercept: "Intercept",
            slope: "Slope",
            rsquared: "r²",
            lrr: "LRR",
            sce: "SCE",
            nsm: "NSM",
            epr: "EPR",
            label: "Class",
          },
        },
        process: {
          extraction: "Extracting shorelines",
          estimate: "ETC",
        },
        exports: {
          title: "Export Data",
          csv: "Export CSV",
          json: "Export JSON",
          shp: "Export Shapefile",
        },
        close: "Close",
      },
      transectEvolution: {
        statistics: "Statistics",
        lrr: "Rate of Change (LRR)",
        r: "Correlation Coefficient (r)",
        sce: "SCE",
        nsm: "NSM",
        epr: "EPR",
        label: "Classification",
        units: {
          meters: "m",
          mByYr: "m/yr",
          mByMonth: "m/yr",
        },
        labels: {
          x: "Year",
          y: "Distance to baseline (m)",
          trend: "Trend",
        },
      },
      imageryOverlay: {
        base: "Base",
        hint: "New layer",
        loading: "Loading",
        plotBathymetry: "Click here to plot Bathymetry image over the map.",
        plotMedian: "Click here to plot Median image over the map.",
        bathymetryLoad: "Click on 'Create' to display the Bathymetry Layer and wait the process be concluded.",
        MedianLoad: "Click on 'Create' to display the Median Layer and wait the process be concluded.",
        add: {
          title: "New layer",
          name: "Layer name",
          expression: "Expression",
          bands: {
            title: "Available Bands",
            red: "RED: Red band",
            green: "GREEN: Green band",
            blue: "BLUE: Blue band",
            nir: "NIR: Near infrared band",
            swir: "SWIR: Shorwave infrared band",
          },
          suggested: "Expressions",
          create: "Create",
        },
      },
      csqueeze: {
        title: "Coastal Squeeze",
        prev: "Back",
        next: "Next",
        year: "Year",
        years: "years",
        landcovers: {
          mangrove: "Mangrove",
          vegetation: "Vegetation",
          water: "Water",
          human: "Human intervention"
        },
        1: {
          title: "Choose satellite",
          description:
            "Select one of the available satellites for image acquisition.",
          card: {
            opticalResolution: "Optical Resolution",
            opticalResolutionUnit: "meters",
            activityPeriod: "Activity Period",
            provider: "Provider",
            revisitTime: "Revisit time",
            revisitTimeUnit: "days",
            choose: "Choose",
          },
        },
        2: {
          title: "Set region of interest",
          description: "Draw the region of interest using the map below.",
          showDelimiters: "Show delimiters",
          undo: "Undo",
          processFile: "Process file",
          importLegend: "Import the area of interest from a KML file:",
          importTitle: "KML import",
          example: "Examples may be downloaded here:",
          dataTrainning: "Data Training",
          dataTrainningLegend: "Import training data",
          dataTrainningFileLabel: "Please inform the date of tranning file:",
          dataTrainningDateHint: "*Use file name ending with _ddmmyyyy.csv to have the training date field automatically filled.",
          bathymetryDescription: "Please define the Area of Interest and the trainning data file to continue.",
          labelBathymetryStep: "Select the area of interest and the trainning data",
          helpCoordinateSystem: "By default CASSIE utilizes the EPSG:4326 coordinates system.\nBut in case your tranning file is based on another EPSG coordinates system,\nyou may type the coordinates system code of your file that CASSIE will interpret your data.",
          performanceMessage: "The marked area is {number} km² and will perform {level} during calculations.",
          slow: "slow",
          moderate: "moderate",
          fast: "fast",
          viewMangrove: "Possible mangrove areas"
        },
        3: {
          title: "Define dates",
          description:
            "Select two different years to get the images",
          loading: "Loading...",
          period: "Period",
          to: "to",
          durationDays: {
            singular: "day",
            plural: "days",
          },
          durationMonths: {
            singular: "month",
            plural: "months",
          },
          durationYears: {
            singular: "year",
            plural: "years",
          },
          imageQuantity: {
            singular: "image",
            plural: "images",
          },
          cloudPercentage: "Cloud percentage",
        },
        4: {
          title: "Select images",
          description: "Filter images to keep the appropriate images only.",
          period: "Period",
          to: "to",
          with: "with",
          tabText: "Select the available dates in the bar below by clicking on the pink dots:",
          emptyImages: "No images found with this percentage of clouds.",
          viewImage: "View image",
          listText: "*Select at least 1 image from this year for processing.",
          addImage: "ADD IMAGE",
          discard: "CANCEL",
          alreadyAdded: "ALREADY ADDED",
          table: {
            id: "ID",
            cloud: "Clouds",
            thumbnail: "Thumbnail",
            remove: "Remove",
          },
          durationDays: {
            singular: "day",
            plural: "days",
          },
          durationMonths: {
            singular: "month",
            plural: "months",
          },
          durationYears: {
            singular: "year",
            plural: "years",
          },
          imageQuantity: {
            singular: "image",
            plural: "images",
          },
          selected: "selected",
          cloudPercentage: "of cloud percentage",
          nextWarning: "Select 1 image from each year to continue"
        },
        5: {
          title: "Classify the land cover",
          description: "Draw regions of land cover types on the map or, if available, select a data source with land cover information.",
          cardText: "Considering that the mangrove grows in areas that can be up to 10 meters above sea level, the compression calculation uses soil elevation filters, with this value being adopted as a standard reference.",
          confirm: "CONFIRM",
          undo: "Remove all",
          example: "Example",
          elevation: "Elevation",
          withElevation: "Elevation of 10 meters",
          noElevation: "No elevation filter",
          defineElevation: "To set an elevation value other than the default change the field below:",
          labels: "Labels",
          labelsInfo: "*Numbers correspond to landcover code.",
          availableDataset: "AVAILABLE DATASET",
          availableDatasetText: "This year has a collection of soil data mapped by",
          apply: "Apply",
          defineLandCovers: "SELECT TYPE TO DRAW ON MAP",
          removePolygon: "Remove polygon",
          export: "Export",
          import: "Import",
          importPolygons: "IMPORT POLYGONS COORDINATES",
          formatImport: "CSV FORMAT: landcover code; south lat; west lng; north lat; east lng",
          spanImport: "After processing the file, the polygons automatically appear on the map",
          toContinue: "*Define at least one mangrove polygon and a different polygon to generate the classification mesh.",
          generate: "GENERATE RASTER",
          confirmRaster: "CONFIRM THE RASTER GENERATED",
          confirmRasterText: "Check the generated raster on the map and, if you are satisfied, check that the rasters of both years are completed before releasing the \"CONTINUE\" button.",
          editPolygons: "EDIT POLYGONS",
          nextStepAlert: "*In the next step it will no longer be possible to change dates, images or the raster.",
          falseColor: "False Color",
          shortcut: "SHORTCUT: You can move the map by keeping the CTRL button pressed.",
          nextWarning: "Generate the two year raster to continue"
        },
        result: {
          steps: "Steps",
          polygons: "Polygons",
          baseLine: "Baseline",
          drawBaseline: "Draw baseline",
          transect: "Transect",
          transects: "Transects",
          visibilityController: "Visibility Controller",
          visibilityControllerDesc: "*More recent year layers overlay older year layers.",
          configTransects: "Configure transects",
          calcule: "Start analysis",
          tipBaseline: "To end the drawing you must double-click on the map at the end point of the line.",
          confirmBaseline: "Do you want to commit the line or redraw?",
          confirm: "Confirm",
          cancel: "Cancel",
          redo: "Redo",
          defineTransects: "Define the amount of transects that will be drawn on the baseline:",
          quantityTransects: "Quantity (approximately)",
          lengthTransects: "Size (for each side in meters)",
          chartsTitle: "Evolution charts",
          loading: {
            wait: "*The process can take a few minutes.",
            preparing: "Preparing data..."
          },
          in: "in",
          output: {
            noMangrove: "There is not enough mangrove for the calculation of this transect.",
            noHuman: "There is not enough human intervention for the calculation of this transect.",
            thisTransect: "in this transect.",
            noSqueeze: "No squeeze",
            squeeze: "Potential of {number}% to squeeze",
            fullSqueeze: "Squeeze potential is greater than 100%",
            organizeYear: "Organizing data from {year}...",
            calculatingTransect: "Calculating transect",
            calculatingComplete: "Calculation of {year} finished...",
            ready: "Ready",
          },
          forecast: "Forecast",
          colorLegend: "Color legend",
          legend: {
            fullSqueeze: "Full squeeze",
            moderateSqueeze: "Moderate squeeze",
            noSqueeze: "Stable",
            noMangrove: "Insufficient mangrove"

          },
          restart: "Restart",
          export: "Export all transects data"
        }
      },

      
      intertidal: {
        title: "Intertidal",
        prev: "Return",
        next: "Continue",
        year: "Year",
        years: "years",
        1: {
          title: "Satellite selection",
          description:
            "Select the available satellite for image aquisition.",
          card: {
            opticalResolution: "Optical Resolution",
            opticalResolutionUnit: "meters",
            activityPeriod: "Activity Period",
            provider: "Provider",
            revisitTime: "Revisit time",
            revisitTimeUnit: "days",
            choose: "Choose",
          },
        },
        2: {
          title: "Selection of area of interest and tide data",
          description: "Selection of area of interest and tide data.",
          showDelimiters: " delimitadores"
        },
        3: {
          title: "Image aquisition",
          description: "Select the images to compose the collection. Ideal that different tidal levels are found.",
          loading: "Loading..."
        },
        4: {
          title: "Acceptence of identified area",
          description: "Analyse the area identified as the intertidal zone.",
          loading: "Loading...",
          period: "Period",
          returnAndRedo: "Return and redo",
        },
        5: {
          title: "Refinamento da área...",
          description: "Refinamento da área....",
          cardText: "Considerando que o mangue cresce em áreas que podem estar até 10 metros acima do nível do mar, o cálculo de compressão utiliza filtros de elevação do solo, com este valor sendo adotado como referência padrão.",
          confirm: "CONFIRMAR",
        },
        result: {
          steps: "Etapas",
          chartsTitle: "Gráfico de evolução"    
        }
      }



    },
  },
};

export default en;
