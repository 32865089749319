import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Zoom from "react-medium-image-zoom";

import { formatDate } from "../../../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 400px",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
}));

// component to display a table of satellite images
const ImageCSqueeze = ({
  images,
  metadata,
  isIncluded = false,
  limit = false,
  onAddImage = () => {},
  onUndo = () => {},
}) => {
  // get the current language
  const [t] = useTranslation();
  const image = images[0];

  const classes = useStyles();

  // a function that gets the metadata for a given image
  const getMetadata = (image, key) => {
    const m = metadata.find(
      (value) => value.date === image.date && value.missionName === image.name
    );
    return m ? m[key] : undefined;
  };

  let url = getMetadata(image, "thumbnail");
  image.thumbnail = url;

  const handleImageAdd = () => onAddImage(image);
  const handleUndo = () => onUndo(image);

  return (
    <Card variant="outlined">
      <CardActions className={classes.root}>
        <Box display="flex" justifyContent="center">
          {url && (
            <Zoom
              zoomMargin={50}
              overlayBgColorStart={"rgba(255, 255, 255, 0)"}
              overlayBgColorEnd={"rgba(255, 255, 255, 0.5)"}
            >
              <img
                src={url}
                alt="Satellite Thumbnail"
                style={{ borderRadius: 5, maxHeight: 350 }}
              />
            </Zoom>
          )}
          {!url && <CircularProgress />}
        </Box>
        <CardContent className={classes.content}>
          <Typography>
            {image.shortname} - {formatDate(image.date, true)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {(image.content * 100).toFixed(1)}% {t("forms.csqueeze.4.cloudPercentage")}
          </Typography>
          <Box
            style={{ width: 200, gap: 10 }}
            display="flex"
            flexDirection="column"
          >
            {!isIncluded ? (
              <Button
                onClick={handleImageAdd}
                // disabled={backDisabled}
                // variant="outlined"
                variant="contained"
                color="primary"
                disabled={limit ? limit : !url}
              >
                {t("forms.csqueeze.4.addImage")}
              </Button>
            ) : (
              <Button variant="contained" color="primary" disabled={true}>
                {t("forms.csqueeze.4.alreadyAdded")}
              </Button>
            )}
            <Button onClick={handleUndo} color="secondary" variant="outlined">
              {t("forms.csqueeze.4.discard")}
            </Button>
          </Box>
        </CardContent>
      </CardActions>
    </Card>
  );
};

export default ImageCSqueeze;
