import { take, select, put, call } from "redux-saga/effects";
import { chunk } from "lodash";
import i18n from "i18next";
import { ee } from "../../../../services/earth-engine";

import { callback, evaluate } from "../../../tools/effects";

import { Actions as Dialog } from "../../dialog";
import { Actions as Snacks } from "../../snacks";
import { Actions as Results } from "../../results";
import { Actions as Map } from "../../map";
import { openAndWait } from "../../dialog/saga";
import { requestAndWait } from "../../map/saga";

import * as Selectors from "../../../../selectors";
import { Timer, generateColors } from "../../../../common/utils";
import * as Metadata from "../../../../common/metadata";
import * as Coastline from "../../../../algorithms/shoreline";
import { ContactSupportOutlined, ControlCameraOutlined } from "@material-ui/icons";
import { ellipse } from "@turf/turf";

export class ResultsCacheHolder {
  static shorelines = null;
  static transacts = null;
}

export const requestCoastlineInput = function* () {
  const dates = yield call(openAndWait, "imageSelection");
  console.log("RequestCostLineInput....");
  if (Array.isArray(dates)) {

    const { coordinates, overlay } = yield call(
      requestAndWait,
      "polyline",
      i18n.t("forms.imageChooser.actions.analyzeShoreline.baselineDraw"),
      "forms.map.baseline",
      "coastlineData");
       
    if (coordinates) {
      const { spacing, extent, threshold } = yield call(
        openAndWait,
        "coastlineConfig"
      );

      if (spacing) {
        let baseline = yield select(
          Selectors.retrieveShapeByName("forms.map.baseline")
        );
        if(typeof(baseline) === "undefined"){
          baseline = {
            content: coordinates
          };
        }else{
          console.log("Selectors", Selectors.retrieveShapeByName("forms.map.baseline"));
          //console.log("baseline", baseline.content[0].geometry.coordinates);
          baseline.content = baseline.content[0].geometry.coordinates;
        }
        yield put(Results.push("baselineData", { baseline }));
        console.log("threshold- shoreline.js - L48", threshold);
        return {
          dates,
          coordinates,
          spacing: parseInt(spacing, 10),
          extent: parseInt(extent, 10),
          threshold: parseFloat(threshold),
        };
      }
      overlay.setMap(null);
    }
  }
  return null;
};

const deriveInChunks = function* (dates, ...params) {
  window.sessionStorage.setItem("ImageDates", JSON.stringify(dates.map( el => {return { date: el.date, dateFormated: new Date(el.date).toISOString()}})));
  const [length, size] = [dates.length, 5];//chunk size: 5
  console.log("params- deriveInChunks", params);
  console.log("Length", length);
  // @TODO improve this
  const progress = (step, etc = "") =>
    `${i18n.t(
      "forms.shorelineAnalysis.process.extraction"
    )} ${step}/${length} ` +
    `${
      etc
        ? `[${i18n.t("forms.shorelineAnalysis.process.estimate")} ${etc}]`
        : ""
    }`;

  yield put(Snacks.task("shore-ext-chunks", progress(0)));

  const evaluated = [];

  const chunks = chunk(dates, size);
  console.log("dates", dates,"size" , size);
  const timer = new Timer();

  // Update snack message and progress load
  const status = () =>
    progress(
      evaluated.length,
      timer.format(timer.project(evaluated.length, length))
    );
  console.log("timer","evaluated.length",evaluated.length,"length", length );
  const load = () => ({
    variant: "determinate",
    value: (evaluated.length / length) * 100,
  });
  let max_tries=1; 
  let chunkNumber = 0;
  window.sessionStorage.setItem("shorelineImagesErrors",JSON.stringify([]));
  for (const chunk of chunks) {
    chunkNumber++;
    let i=0;
    let chuckProcessed=false;
    let technicalError="";
    while(i<max_tries){
      //  console.log(i, max_tries, chunk);
        i++;
        console.log("Init chunck:", chunkNumber ,chunk);
        let shorelines = null;
        //comparar quantidade de imagens na collection no intervalo de datas 
      try {
          shorelines = yield call(
          Coastline.deriveShorelines,
          chunk,
          ...params //trocar para TOA se der erro com a collection SR
        );  
        //lançar os shorelines ok.... 
        //console.log("conclude shoreline for chunk - sucess", chunkNumber);
      //  console.log("shorelines", shorelines);
      //  console.log("shorelines-getInfo()", ee.List(shorelines).getInfo());
       // let result = yield evaluate(ee.List(shorelines));
       // console.log("RESULT-evaluate", result );
        //console.log("shorelines-TEXT", JSON.stringify(shorelines[0]));
        //Excluir entradas do tipo: LineRing (se houver)
        // https://developers.google.com/earth-engine/apidocs/ee-list-filter
        //let shoreLineFiltered = ee.List(shorelines).filter(ee.Filter.eq("geometry.type", "LineString"));
       // console.log("shorelinesFiltered", shoreLineFilteredInfo);
       if(shorelines===null) continue;// go to next loop
       shorelines = shorelines.filter((el) => el === null ? false : true);
      
        const job = ee.List(shorelines);
       console.log("shorelines","job", job, job.getInfo() );
       // console.log("job.evaluate", job.evaluate );
       console.log("Evaluating....");
       const data = yield callback([job, job.evaluate]); //thresholdingAlgorithm - file extraction.js
       console.log("Evaluated...", data);
       console.log("data-sucess", data, "for chunk", chunkNumber);

        evaluated.push(...data);
        i=max_tries;
        chuckProcessed=true;
      } catch (e) {
        // @TODO i18n
        // Ocorrendo o erro ::> tentar novamente com chunks individuais...
        technicalError=e; 
        console.error(`[ducks/shoreline] ERROR: ${e}`,"chunk number", chunkNumber,"chunk", chunk,"shorelines", shorelines);
      }
  }
  if(!chuckProcessed){
   try{
      document.getElementById("message_loading").parentElement.parentElement.parentElement.style.display = "none";
   }catch(e1){
      //revisar....
   }
    let friendlyErrorMessage="The Google Earth Engine (GEE) was not able to complete this processing.\n\n";
    friendlyErrorMessage+="You may try again later.\n\n";
    console.error( friendlyErrorMessage+`Error in chunk ${chunkNumber}. Fragment will be ignored.`+"\n\nTechnical error message: \n"+technicalError );
   /*
    yield put(
      Snacks.error(
        `shore-ext-chunks-err${evaluated.length}`,
        friendlyErrorMessage+`Error in chunk ${evaluated.length / size}. Fragment will be ignored.`+"\n\nTechnical error message: \n"+technicalError
      )
    );
    */
  }

    yield put(Snacks.update("shore-ext-chunks", status(), load()));//print result is here
  }
  
  yield put(Snacks.dismiss("shore-ext-chunks"));
  yield put(Snacks.success("shore-ext-success", "Shorelines derived"));
  //yield put({ type: "DONE" });
  console.log("ShorelineDerived","evaluated", evaluated);
  return ee.FeatureCollection(evaluated);
};

const performCoastlineAnalysis = function* (
  identifier,
  baseline,
  transects,
  extent,
  dates,
  threshold,
  names = []
) {
  const { satellite } = yield select(Selectors.getAcquisitionParameters);
  ResultsCacheHolder.transacts = transects;
  const roi = baseline.buffer(extent / 2);
  console.log("transects input", transects);
  const sds = yield call(
    deriveInChunks,
    dates,
    satellite,
    roi,
    30,
    threshold,
    transects
  );
  console.log("SDS", sds,sds.getInfo());
  ResultsCacheHolder.shorelines = sds;
  

  const results = yield call(
    Coastline.generateTransectsStatistics,
    transects,
    baseline,
    sds,
    names
  );
console.log("Results", results, results.getInfo());
  const transectsViz = yield call(Coastline.expandHorizontally, results, 10);
  console.log("transectsViz", transectsViz, transectsViz.getInfo());
  let shorelines = yield call(
    Coastline.summaryShorelineStatistics,
    results,
    sds,
    roi
  );
 
  console.log("shorelines stats", shorelines, shorelines.getInfo());
  const shoreColors = generateColors(dates.length, 66);
  console.log("shoreColors", shoreColors);
  const transectLabels = yield evaluate(
    results.map((f) => ee.Feature(f).get("label"))
  );
  console.log("transectLabels", transectLabels);
  const transectColors = transectLabels.map(
    (label) => Metadata.ESTEVES_LABELS[label].color
  );
  

 //todo- parametrize shoreline and trasenctsViz
  //Draw shorelines on the map
  let shorelinesData = shorelines.getInfo();
  //console.log("Drawing shorelines....",shorelines,shorelines.getInfo(),shoreColors);
  let newFeatures = [];
  shorelinesData.features.forEach(el=>{
   // console.log("Drawing shorelines.... DEBUG", el.geometry.type, el.geometry.coordinates);
    let coordinates =  el.geometry.coordinates;
    if(el.geometry.type === "LinearRing"){
      console.log("Is linear ring: ", coordinates);
      coordinates = coordinates.slice(0, coordinates.length -1);
      console.log("Is linear ring: ", coordinates);
    }
    let newFeature = ee.Feature(ee.Geometry.LineString(coordinates), el.properties);
    newFeatures.push(newFeature);
    return el;
  });
  console.log("newFeatures", newFeatures);
  shorelines = ee.FeatureCollection(newFeatures);
  //console.log("Drawing shorelines after transform....",shorelines,shorelines.getInfo(),shoreColors);
  yield put(
    Map.addEEFeature(
      shorelines,
      "forms.map.shorelines",
      shoreColors,
      1,
      identifier
    )
  );
  console.log("Drawing shorelines end....");  
  //Draw transects on the
 // console.log("Drawing transects....",transectsViz,transectsViz.getInfo(), transectColors);
  //yield put(Results.push(identifier, { transectData, shorelineData })); 
  yield put(
    Map.addEEFeature(
      transectsViz,
      "forms.map.transects.title",
      transectColors,
      1,
      Metadata.FeatureType.TRANSECT
    )
  );
  console.log("Drawing transects end....");    
 //draw statistics table
 const [transectData, shorelineData] = [results.getInfo(),shorelines.toList(shorelines.size())];
 yield put(Results.push(identifier, { transectData, shorelineData })); 
 //const [transectData, shorelineData] = yield evaluate( ee.List([results, shorelines.toList(shorelines.size())]));
  console.log("shoreline.js","CoastlineEvolutionDialogOpen",identifier, { transectData, shorelineData },"shorelines", shorelines);
  yield put(Results.push(identifier, { transectData, shorelineData }));
  console.log("shoreline.js","CoastlineEvolutionDialogOpenEnd");
  yield put(Dialog.open("coastlineEvolution"));
  //print excluded images report
  
};

export const handleAnalyzeCoastline = function* () {
  const identifier = "coastlineData";

  yield put(Map.removeShapeGroup(identifier));

  const input = yield call(requestCoastlineInput);

  if (input) {
    const { coordinates, spacing, extent, dates, threshold } = input;
    console.log("threshold-shoreline.js-L258", threshold);
    const baseline = ee.Geometry.LineString(coordinates);
    console.log("Calculating transects....");
    const transects = yield call(
      Coastline.generateOrthogonalTransects,
      coordinates,
      spacing,
      extent
    );

    console.log("TRANSECTS-Calculate", transects,transects.getInfo());
    yield call(
      performCoastlineAnalysis,
      identifier,
      baseline,
      transects,
      extent,
      dates,
      threshold
    );
  }
};
