import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import { formatDate } from "../../../common/utils";
import SatelliteImageThumbnail from "../visualization/SatelliteImageThumbnail";

import moment from "moment";

const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);

// component to display a table of satellite images
const ImageTableCSqueeze = ({
  images,
  onCheckboxChange = () => {},
  rebuild,
}) => {
  // get the current language
  const [t] = useTranslation();
  const [table, setTable] = useState(images);

  useEffect(() => {
    if (images.length > 1) {
      setTable(
        images.sort((a, b) => toTimestamp(b.date) - toTimestamp(a.date))
      );
    } else {
      setTable(images);
    }
  }, [rebuild]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("forms.csqueeze.4.table.id")}</TableCell>
          <TableCell>{t("forms.csqueeze.4.table.cloud")}</TableCell>
          <TableCell>{t("forms.csqueeze.4.table.thumbnail")}</TableCell>
          <TableCell>{t("forms.csqueeze.4.table.remove")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {table.length > 0 ? (
          table.map((image, index) => (
            <TableRow key={index}>
              <TableCell>
                {image.shortname} - {formatDate(image.date, true)}
              </TableCell>
              <TableCell>{(image.content * 100).toFixed(1)}%</TableCell>
              <TableCell>
                <SatelliteImageThumbnail
                  url={table[index].thumbnail}
                  height={100}
                />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => onCheckboxChange(index)}
                  variant="contained"
                  color="secondary"
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4} align="center">
              <Typography variant="overline" align="center">
                {t("forms.csqueeze.4.listText")}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ImageTableCSqueeze;
