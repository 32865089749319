import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ee from "../../../services/earth-engine"; // @TODO remove this!
import { Box, Grid, Grow } from "@material-ui/core";
import GoogleMap from "../../components/map/GoogleMap";
import ImageChooserCard from "../../components/map/ImageChooserCard";
import ActionsCard from "../../components/map/ActionsCard";
import { ShapeList } from "../../components";
import LoadedImagesAccordion from "../../components/map/LoadedImagesAccordion";
import { Actions as Map } from "../../../store/ducks/map";
import { Actions as Snacks } from "../../../store/ducks/snacks";
import { useTranslation } from "react-i18next";
import TourGuider from "../../components/tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import { Chip, Divider } from "@material-ui/core";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
//imports to handle layers
import { generateLayer, createThumbnail } from "../../../algorithms/imagery";
//import { Actions as Imagery } from "../../../store/ducks/imagery";
import * as Imagery from "../../../store/ducks/imagery/header";
import * as Selectors from "../../../selectors";
import { call, put, select } from "redux-saga/effects";
import { ConcreteLayer } from "../../../common/classes";
import { callback } from "../../../store/tools/effects";
import {RawMapHelper} from  "../../../store/ducks/imagery/saga/layers";
import { Layer } from "../../../common/classes";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import { get as getSatellite } from "../../../common/satellites";
import {acquireFromDate as acquireFromDateSentinel, maskS2Clouds, maskS2ImageMask} from "../../../algorithms/satellite/sentinel";
import {acquireFromDate as acquireFromDateLandSat, maskLandsatImageMask, maskLandsatCloudsRatio} from "../../../algorithms/satellite/landsat";


import createPlotlyComponent from 'react-plotlyjs';

import Plotly from 'plotly.js-basic-dist';
//https://code.earthengine.google.com/8f89e32da5988658c558133485d0348b
export class BathymetryResults{
  static bathymetryImage = null;
  static bathymetryParams = null;
  static predictedSentinel = null;
  static mission = null;
  static eeImageToProcess = null;
  static baseImageDate = null; 
  static projection = null;
  static hideBathymetryPivotLayer = false;
  static thresholdValue = 0;
  static bandsSelected = [];
  static algorithmSelected = "";
  static downloadURLZip = "";
  static useMedian = false;
  static predictedData = null;
  static slope = 0;
  static geometry = null;
  static interception = 0;
  static urlZipDownloadDate = "";
  static urlZipDownloadMedian = "";
  static minimumDepth = 0;
  static showMedianLayer = false;
  static isBathymetry = function (){
    let url = window.location.href;
    return url.indexOf("bathymetry") != -1;
  }

  static getTrainingDataAsMatrix = function (){
    let text = window.sessionStorage.getItem("tranningData");
    text = text.trim();
    return JSON.parse(text);
  }
  static getTrainingDataAsFeatureCollection = function (){
    let text = window.sessionStorage.getItem("tranningData");
    text = text.trim();
    let trainingData = JSON.parse(text);
    let features = [];
    console.log("init conversion of training data to eeFeatureCollection");
    trainingData.forEach(point => {
        if(!(point[0] === null || point[1] === null || point[2] === null)){
          var geometryMultiPoint = ee.Geometry.MultiPoint([],BathymetryResults.getCoordinatesSystem()); //"EPSG:4326"
          let z =  point[2] > 0 ? point[2] * -1 : point[2] ; //BathymetryResults.getCoordinatesSystem() === "EPSG:32722" ?: point[2] ;
          let f1 = ee.Feature(geometryMultiPoint, {Y: parseFloat(point[1]), X: parseFloat(point[0]), Z: parseFloat(z)  }); 
          features.push(f1); 
      }
    });
    var fc = ee.FeatureCollection(features);
    return  fc;
  }

  static getCoordinatesSystem = function(){
    let coordinatesSystem = window.sessionStorage.getItem("coordinatesSystem");
    //console.log("Coordinates system", coordinatesSystem);
    return coordinatesSystem !== null && coordinatesSystem !== "" ? coordinatesSystem : "EPSG:32722";
  }
  static medianImageParams = [];

  static getMedianImageSentinel = function (collectionName, geometry, dtBegin, dtEnd){
    var cloudBitMask = ee.Number(2).pow(10).int();
    var cirrusBitMask = ee.Number(2).pow(11).int();
 
    // Mask out land and clouds
    function mask(img){
      var qa = img.select('QA60');
      var ma = qa.bitwiseAnd(cloudBitMask).eq(0).and(
                qa.bitwiseAnd(cirrusBitMask).eq(0));
      /*
      ma = ma.and(img.select(['SCL']).neq(3));
      ma = ma.and(img.select(['SCL']).neq(4));
      ma = ma.and(img.select(['SCL']).neq(5));
      ma = ma.and(img.select(['SCL']).neq(8));
      ma = ma.and(img.select(['SCL']).neq(9));
      ma = ma.and(img.select(['SCL']).neq(10));
      */
    
      ma = ma.and(img.select(['B9']).lt(300)); 
      ma = ma.and(img.select(['B9']).gt(50));
      
      ma = ma.and(img.select(['B3']).gt(100));
      //adjust for mask bad data
      img = img.updateMask(img.select([4]).lt(1000));
      img = img.updateMask(img.select([7]).lt(300))
      ma = ma.focal_min({kernel: ee.Kernel.circle({radius: 1}), iterations: 1});
      img = img.mask(ma);

      var ndwi_revise = (img.select([2]).subtract(img.select([7]))).divide(img.select([2]).add(img.select([7])));
      img = img.updateMask(ndwi_revise.gt(0));
    
      return img;
    };
    
    let collection = ee.ImageCollection(collectionName).filterBounds(geometry).filterDate(dtBegin, dtEnd);
    collection = collection.map(mask);
    // Compute the median in each band, each pixel.
    // Band names are B1_median, B2_median, etc.
    var medianImage = collection.reduce(ee.Reducer.median());
    // The output is an Image.  Add it to the map.
   // var vis_param = {bands: ['B4_median', 'B3_median', 'B2_median'], gamma: 1.6};
    let medianThumbURL = medianImage.getThumbURL({region: geometry,bands:['B4_median', 'B3_median', 'B2_median'], dimensions: 256,max:1700, min:300, format: 'jpg'});
    medianImage = medianImage
    .select(['B1_median', 'B2_median', 'B3_median', 'B4_median', 'B5_median', 'B6_median', 'B7_median', 'B8_median', 'B8A_median', 'B9_median',  'B11_median', 'B12_median', 'QA10_median', 'QA20_median', 'QA60_median'])
    .rename(['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B8A', 'B9',  'B11', 'B12', 'QA10', 'QA20', 'QA60']);
    medianImage = medianImage.toInt();
    return [medianThumbURL, medianImage];
    //console.log("thumbURL Median", medianThumbURL);
  }

  static getMedianImageLandsat = function (collectionName, geometry, dtBegin, dtEnd){
      // Compute the median in each band, each pixel.
      // Band names are B1_median, B2_median, etc.
      var NDWIindex = function(image) {
        var ndwi = image.normalizedDifference(['SR_B3', 'SR_B5']).rename('ndwi'); //Mc Feeters, 1996
     return image.addBands([ndwi]);
     };
     
     var mask_land = function (image){
       var ndwi = image.select('ndwi');
       return image.updateMask(ndwi.gte(0))
      };

      let finalCollection = ee.ImageCollection(collectionName).filterBounds(geometry).filterDate(dtBegin, dtEnd);
      finalCollection = finalCollection.map(NDWIindex);
      finalCollection = finalCollection.map(mask_land);
      var medianImage = finalCollection.reduce(ee.Reducer.median());
      // The output is an Image.  Add it to the map.
      medianImage = medianImage.clip(geometry);
      var medianThumbURL = medianImage.getThumbURL({region: geometry,bands:['SR_B4_median', 'SR_B3_median', 'SR_B2_median'], dimensions: 512,max:20000, min:7000, format: 'jpg'});
      //var singleBandVis = {max:30000, min:0, bands:['SR_B4_median', 'SR_B3_median', 'SR_B2_median']};
     // Map.addLayer(medianImage,singleBandVis ,"Median");
    medianImage = medianImage.select(['SR_B1_median', 'SR_B2_median', 'SR_B3_median', 'SR_B4_median', 'SR_B5_median', 'SR_B6_median', 'SR_B7_median', 'SR_QA_AEROSOL_median', 'QA_PIXEL_median']).rename(['SR_B1', 'SR_B2', 'SR_B3', 'SR_B4', 'SR_B5', 'SR_B6', 'SR_B7', 'SR_QA_AEROSOL', 'QA_PIXEL']);
    medianImage = medianImage.toInt();
    console.log("Landsat-median url", medianThumbURL);
    return [medianThumbURL, medianImage];
  }
  static getMedianImage = function (collectionName, geometry, dtBegin, dtEnd){
    if(collectionName.indexOf("LANDSAT") !== -1){
      console.log("Median Landsat...");
      return BathymetryResults.getMedianImageLandsat(collectionName, geometry, dtBegin, dtEnd);
    }else{
      console.log("Median Sentinel...");
      return BathymetryResults.getMedianImageSentinel(collectionName, geometry, dtBegin, dtEnd);
    }
  }

  static getSelectedSatellite(){
    let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
    let satellite = getSatellite(selectedSatelliteIndex);
    return satellite;
  }

  static getSelectedImage = function (){
    let dt = window.sessionStorage.getItem("selectedImageDateFormated"); 
    let geometry = BathymetryResults.geometry;
    let satellite =  BathymetryResults.getSelectedSatellite(); 
    let imageAndMission = BathymetryResults.getImageOnDate( dt, satellite, geometry);
    var image = ee.Image(imageAndMission[0]);
    let bandName = imageAndMission[1].bands.red;
    BathymetryResults.projection = image.select(bandName).projection().getInfo();//ex. bandName = B3
    if(BathymetryResults.useMedian){    
      let params = BathymetryResults.medianImageParams;
      console.log("Using median image", params);
      let medianImageResult = BathymetryResults.getMedianImage(params[0], params[1], params[2], params[3]);    
      image = medianImageResult[1];
      //console.log("Median image calculated",medianImageResult[0])
    }
    return image;
  }

  static getImageOnDate = function(dateFormated, satellite, geometry){ 
    let imageList = [];
    let filterYear = Number.parseInt(dateFormated.split("-")[0]);
    satellite.missions.forEach( ms => {
      if(ms.endYear === null || ms.endYear > filterYear) {
        let im  = null;
        if(satellite.name === "Landsat"){
          im =  ee.Image(acquireFromDateLandSat(dateFormated, ms, geometry));
        }else{
          im =  ee.Image(acquireFromDateSentinel(dateFormated, ms, geometry)); 
        }
        
        imageList.push([im,ms]);
      }
    });
    return [imageList[0][0], imageList[0][1] ];
  };

  static mask =  function (img){
    const satellite = BathymetryResults.getSelectedSatellite();
    const mission = satellite.missions[0];
    const geometry = BathymetryResults.geometry;
    if(satellite.name.indexOf("Sentinel")!== -1 ){//mask for sentinel
      img = maskS2ImageMask(img, mission);
      var ndwi_revise = (img.select([2]).subtract(img.select([7]))).divide(img.select([2]).add(img.select([7])));
      img = img.updateMask(ndwi_revise.gt(0));   
      let ratio = maskS2Clouds(img, BathymetryResults.geometry, mission.bands.qa, mission.opticalResolution );
      return img.set("CLOUDS", ratio);
    }else{ //mask for landsat
      img = maskLandsatImageMask(img, mission);
      let ratio = maskLandsatCloudsRatio(img, geometry,mission.bands.qa);
      return img.set("CLOUDS", ratio); 
    }
  }
}

// useStyles is a hook for Material-UI's styling.
const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: "100vh",
    flexGrow: 1,
    position: "relative", 
  },
  mapContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexFlow: "column",
  },
  mapOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 10,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "auto",
      },
    },
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
}));

// main function
const ProcessingBathymetryPage = () => {
  let ms = JSON.parse(window.sessionStorage.getItem("selectedMission")); //BathymetryResults.getSelectedSatellite().missions[0];   
  console.log("ms-ProcessingBathymetryPage ",ms);
  // check if the user is drawing a shape
  const isDrawing = useSelector((state) => state.map.currentlyDrawing);
  // get the map coordinates
  const coordinates = useSelector((state) => state.acquisition.coordinates);
  const dispatch = useDispatch();
  // custom styles
  const classes = useStyles();
  // get the current language
  const [t] = useTranslation();
  let minimumPredictedDepth = 0;
  let maximumPredictedDepth = 0;
  let data = [];
 
  let geometry =  ee.Geometry.Polygon(coordinates); 
  //let visRGB = {region: geometry, "min":0,"max":3000,"bands":["B4","B3","B2"]};
  let visDepth = {region: geometry, dimensions: 256, "bands":["depth"],"min":-20,"max":0,"palette":["0000FF","00FFFF"]};//format: 'jpg'
  const [thumbURL, setThumbURL] = useState("");
  const [plotlyData, setPlotlyData] = useState([]);
  // function that displays a Geometry feature in ee with the given coordinates
  const displayROI = () => {
    if (coordinates) {
      // create a new ROI
      const feature = ee.Feature(ee.Geometry.Polygon([coordinates]));
      // get the border
      const border = feature.buffer(15).difference(feature);
      // display the border in the map in #00A391
      dispatch(Map.addEEFeature(border, "forms.map.roi", "#00A391", 0.6));
      //imagem pronta: predicted_sentinel
      //start addLayer:
      BathymetryResults.hideBathymetryPivotLayer = true;
      console.log("Using basedate", BathymetryResults.baseImageDate);
      console.log("Acquire from date....", ms.name);
      //acquireFromDate( BathymetryResults.baseImageDate, ms, geometry)
      dispatch(
        Acquisition.acquireImage(
            ms.name,//'COPERNICUS/S2_HARMONIZED',
            BathymetryResults.baseImageDate//"2017-05-14T13:10:45"
          ) 
        );
        //end addLayer
        // center the map on the coordinates
        dispatch(Map.centralizeMap(coordinates));
    }
  };

  let dialog = document.createElement("dialog");
  document.body.appendChild(dialog);
  dialog.innerHTML = t("forms.acquisition.5.bathymetry.processing");
 
  // defines the steps for the tour
  const steps = [
    {
      selector: "#mapScreen",
      content: t("tour.map.start"),
    },
    {
      selector: "#imageChooserForm",
      content: t("tour.map.imageChooser.info"),
    },
    {
      selector: "#imageChooserSelect",
      content: t("tour.map.imageChooser.select"),
    },
    {
      selector: "#imageChooserLoadButton",
      content: t("tour.map.imageChooser.click"),
    },
  ];

  if(thumbURL === "" ){
    dialog.showModal();
  setTimeout(() => {

    //start: bathymetry code
    var table = BathymetryResults.getTrainingDataAsFeatureCollection(); //ee.FeatureCollection("users/rafaelqg/batimetria_babitonga_2018_50K");
    // Create a FeatureCollection containing each points' band information and depth
    function createPoints(el) {
      return ee.Feature(ee.Geometry.Point([el.get('X'),el.get('Y')], BathymetryResults.getCoordinatesSystem()), //'EPSG:32722'
      {'depth': el.get('Z')})
    }
    // Calculate the SDB value (selected bands) and the Reason (selected bands)
    function calculateReason(el) {
      let bandA = BathymetryResults.bandsSelected[0];
      let bandB = BathymetryResults.bandsSelected[1];
      return ee.Feature(el.geometry()).set('depth', ee.Number(el.get('depth')).toFloat()) 
      .set('ndi', ee.Feature(ee.Number(el.get(bandA)).subtract(el.get(bandB)).divide(ee.Number(el.get(bandA)).add(el.get(bandB)))))
      .set('ratio', ee.Feature(ee.Number(el.get(bandA)).multiply(1).log().divide(ee.Number(el.get(bandB)).multiply(1).log())))
      .set('constant', 1);
    }
    // Predict depth based on the linear model results
    function predictDepth(el) {
      return ee.Feature(el.geometry()).set('depth', el.get('depth'))
      .set('predicted_depth', ee.Number(el.get(BathymetryResults.algorithmSelected)).multiply(slope).add(yInt))
      .set('diff', ee.Number(el.get('depth')).subtract(ee.Number(el.get(BathymetryResults.algorithmSelected)).multiply(slope).add(yInt)))
    }
    // Create raster with the predicted depth
    function createPredictionRaster(el) {
      let bandA = BathymetryResults.bandsSelected[0];
      let bandB = BathymetryResults.bandsSelected[1];
      var sdb = BathymetryResults.algorithmSelected === "ndi" 
        ? el.select(bandA).subtract(el.select(bandB)).divide(el.select(bandA).add(el.select(bandB))).rename('sdb')
        : el.select(bandA).multiply(1).log().divide(el.select(bandB).multiply(1).log()).rename('sdb');
      el = el.addBands(sdb);
      var depth = el.select('sdb').multiply(ee.Number(slope)).add(ee.Number(yInt)).rename('depth');
      el = el.addBands(depth);
      return el;
    }


    //
    console.log("DEBUG","bandsSelected",BathymetryResults.bandsSelected);
    console.log("DEBUG","algorithmSelected",BathymetryResults.algorithmSelected);
    var points = table.map(createPoints);
    //console.log("points", points,points.getInfo());
    let image = BathymetryResults.getSelectedImage();
    //console.log('L0-ThumbURL', image.getThumbURL({region: geometry,dimensions: 256,format: 'jpg', "bands":bandsNames,"min":7000,"max":20000})); 
    
    // Associate each point with the band values retrived from the Sentinel image
    var pointFilter = points.filterBounds(geometry);
    var pointData = image.reduceRegions({
      collection: pointFilter,
      crs: ee.Projection(BathymetryResults.getCoordinatesSystem()),
      scale: ms.opticalResolution, 
      reducer: ee.Reducer.median()
    });

    pointData = pointData.filter(ms.bands.blue +' != null');
    // Filter points with depth ≤ 17 meters
    pointData = pointData.filter(ee.Filter.lte('depth', BathymetryResults.thresholdValue));// trocar o 17 pelo thresold informado
    var trainingData = pointData.map(calculateReason, true);
    // Apply a Linear Regression model to the data
    var linearRegression = ee.Dictionary(trainingData.reduceColumns({
    reducer: ee.Reducer.linearRegression({
      numX: 2,
      numY: 1 
    }),
    selectors: ['constant', BathymetryResults.algorithmSelected , 'depth']
    }));

    var coefList = ee.Array(linearRegression.get('coefficients')).toList();
    var yInt = ee.List(coefList.get(0)).get(0); // y-intercept
    var slope = ee.List(coefList.get(1)).get(0); // slope
    BathymetryResults.slope = slope;
    BathymetryResults.interception = yInt;
    // Predict depth of the training data to measure RMSE
    var predictedData = trainingData.map(predictDepth, true)
      BathymetryResults.predictedData = predictedData;
    // Export.table.toDrive(predictedData, "predictedDepth200518")
    // Create masked Sentinel image
    //console.log('L1-ThumbURL', image.getThumbURL({region: geometry,dimensions: 256,format: 'jpg', "bands":bandsNames,"min":7000,"max":20000})); 
    let predicted_image =  BathymetryResults.mask(image); //BathymetryResults.useMedian ? image : BathymetryResults.mask(image);
    predicted_image = predicted_image.clip(geometry);
    
    //console.log('L2-ThumbURL', predicted_image.getThumbURL({region: geometry,dimensions: 256,format: 'jpg', "bands":bandsNames,"min":7000,"max":20000}));
    // Create prediction raster
    predicted_image = createPredictionRaster(predicted_image);  
    predicted_image = predicted_image.updateMask(predicted_image.select(["depth"]).lte(0));
    //predicted_sentinel = predicted_sentinel.toInt();
    //visDepth = visRGB; //only for displaying layer
    BathymetryResults.bathymetryImage = predicted_image;
    BathymetryResults.bathymetryParams = visDepth;
    BathymetryResults.mission =  ms;
    dialog.close();
    //visDepth.crs = ee.Projection(BathymetryResults.getCoordinatesSystem());//EPSG:32722
    //visDepth.scale = ms.opticalResolution;
    let bathymetryThumbnailURL = predicted_image.getThumbURL(visDepth);
    setThumbURL(bathymetryThumbnailURL);    
    let predicted = predicted_image.select(['depth']);
    BathymetryResults.downloadURLZip = predicted.getDownloadURL({
      region: geometry,
      scale: ms.opticalResolution,
    }); 
    loadDepth();

   
    //console.log("thumbURL", thumbURL);
    //console.log("thumbURLZip", BathymetryResults.downloadURLZip);
  }, 1000); 
  
}

let returnToBegin = function(){
  window.location.href = "/main/selection";//http://localhost:5001
}
  let loadDepth = function(){
    console.log("load Depth L1");
    console.log("loadDepth-ms",ms);
    dialog.showModal();
    setTimeout( () => { 
      let predicted_sentinel = BathymetryResults.bathymetryImage;
      console.log("Calculating depth");

        var minMax = ee.Feature(null,
          predicted_sentinel
          .reduceRegion({
            reducer: ee.Reducer.minMax(),
            geometry: geometry, 
            crs: BathymetryResults.projection.crs,
            scale: ms.opticalResolution
          })
          );

        // Calculate RMSE value
        let predictedData = BathymetryResults.predictedData;

        // Calculate the square root of difference between depth measured and predicted 
        var predictedDataDiff2 = predictedData.map(function(featureSquareDiff) {
          // Ensure that featureSquareDiff("diff") is converted to a number
          var diff = ee.Number(featureSquareDiff.get("diff"));
          // Check if diff is not null or undefined
          var diffPow2 = diff !== null ? diff.pow(2) : null;
          // Set "diff2" property with the squared value
          //return featureSquareDiff.set("predictedDataDiff2", diffPow2);
          return featureSquareDiff.set("diff2", diffPow2);
        });


        let bias = ee.Number(
          predictedData
          .reduceColumns(ee.Reducer.sum(), ['diff'])
          .get('sum')
        )
        .divide(predictedData.size());

        //let rmse = bias.sqrt();
        let rmse = ee.Number(
          predictedDataDiff2
          .reduceColumns(ee.Reducer.sum(), ['diff2'])
          .get('sum')
        ).divide(predictedDataDiff2.size()).sqrt();
        
        
        let eeArray = ee.Array([minMax.getNumber('depth_min'), minMax.getNumber('depth_max'),rmse,bias,BathymetryResults.slope, BathymetryResults.interception]);
        eeArray = eeArray.getInfo();
        eeArray[0] =  eeArray[0] < 0  ?  eeArray[0]*-1 :  eeArray[0];
        eeArray[1] =  eeArray[1] < 0  ?  eeArray[1]*-1 :  eeArray[1];
        //console.log("outputs-statistics",rmse, bias, BathymetryResults.slope,  BathymetryResults.interception);
        document.getElementById("rmse").innerHTML = eeArray[2].toFixed(2);
        document.getElementById("bias").innerHTML =  eeArray[3].toFixed(2);;
        document.getElementById("slope").innerHTML =  eeArray[4].toFixed(2);
        document.getElementById("interception").innerHTML =  eeArray[5].toFixed(2);
        document.getElementById("min").innerHTML = eeArray[0].toFixed(2);
        document.getElementById("max").innerHTML = eeArray[1].toFixed(2);
        BathymetryResults.minimumDepth = eeArray[0];
        let csvOutput = "";
        csvOutput += "min, max, rmse, bias, slope, interception\n";
        csvOutput += eeArray.join(", ");
        let text = csvOutput;
        let type = "text/plain";
        let name = "bathymetry_statistics.csv";
        let a = document.getElementById("statistics_anchor");
        let file = new Blob([text], {type: type});
        a.href = URL.createObjectURL(file);
        a.download = name;
        //a.click();
        a.style.display = "block";

        //csv bathymetry
        try{
          //export csv
          console.log("Generating csv file to export...");
          let predicted = predicted_sentinel.select(['depth']); //'B1', //let bandA = BathymetryResults.bandsSelected[0];
          var depthIntegral = predicted.select(['depth']).int(); 
          let depthFloat = predicted.select(['depth']).float();
          depthIntegral = depthIntegral.addBands(depthFloat);
          var bounds = predicted.geometry(); //depthIntegral.geometry(); 
          
          const label = 'vectors';
          var img2point = depthIntegral.reduceToVectors({
                  //reducer: ee.Reducer.count(),
                  geometry: bounds,
                  scale: ms.opticalResolution, 
                  geometryType: 'centroid', 
                  eightConnected: true,
                  maxPixels: 1e9,
                  labelProperty: label,
                  reducer: ee.Reducer.max()//ee.Reducer.mean() 
                });
          let dataArray = img2point.getInfo();
          //console.log("dataArray", dataArray);
          csvOutput = "latitude, longitude, depth\n";
          dataArray.features.forEach(point=>{ 
            csvOutput += point.geometry.coordinates[0]+",";
            csvOutput += point.geometry.coordinates[1]+",";
            csvOutput += point.properties.max +"\n";
          });       
          text = csvOutput;
          type = "text/plain";
          name = "bathymetry.csv";
          a = document.getElementById("depth_csv_anchor");
          file = new Blob([text], {type: type});
          a.href = URL.createObjectURL(file);
          a.download = name; 
          //a.click();
          a.style.display = "block";
      }catch(e){
        console.error("Error generating csv", e);
      }finally{
        dialog.close();//close loading dialog (relase screen to user)
      }

        //Plotly
    console.log("Init plotly");
    BathymetryResults.minimumDepth = BathymetryResults.minimumDepth < -1 ? BathymetryResults.minimumDepth * -1 : BathymetryResults.minimumDepth;
    BathymetryResults.minimumDepth = Math.ceil(BathymetryResults.minimumDepth);
    console.log("Init plotly",  BathymetryResults.minimumDepth);
    let stepValue  = Math.ceil(BathymetryResults.minimumDepth / 5);
    console.log("stepValue", stepValue);
    data = [
      {
        x: [1, 2, 3, 4, 5,6],
        y: [1, 2, 3, 4, 5,6],
        mode: 'markers',
        marker: {
          size: 6,
          color: [0, stepValue*-1, stepValue*-2, stepValue*-3,stepValue*-4, stepValue*-5],
          colorscale: [[0, "0000FF"],[1,"00FFFF"]], //'Blues', //'Viridis', //https://community.plotly.com/t/what-colorscales-are-available-in-plotly-and-which-are-the-default/2079
          //custom colorscale ==> https://community.plotly.com/t/manually-customize-colorbar-scatter-python/18520
          colorbar: {
            title: 'Depth(m)',
            thickness: 25 , // color graphical width
            ticks: 'outside',
            ticklen: stepValue
          }
        }
      }
    ];
    console.log("Init plotly - data",  data);
      setPlotlyData(data);
      }, 1000);
  };
  //end: bathymetry code
  console.log("Plotly init... a)");
  //Plotly
  const PlotlyComponent = createPlotlyComponent(Plotly);
  
  var layout = {title: 'My Plot'}; 
  function cleanPlotyChartColorbarOnly(){
    let el = document.getElementsByClassName("main-svg");
    let children = el[0].children;
    for(let i=0; i < children.length; i++){
      let node = children[i];
      //if(node.className !== "infolayer" && node.className !== "indicatorlayer" ) node.style.display = "none";
      if(node.className !== "infolayer" && node.className !== "indicatorlayer" ) node.style.visibility = "hidden";
    }
    el = document.getElementsByClassName("modebar-container");
    el[0].style.visibility = "hidden";
    el = document.getElementsByClassName("g-gtitle");
    el[0].style.visibility = "hidden";
  }
  console.log("Plotly init... b)");
  //Plotly
  setInterval(cleanPlotyChartColorbarOnly, 2000);
  let config = {
    showLink: false,
    displayModeBar: true
  };
 
  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showMapTour", true);
  console.log("Bathymetry - map");

  return (
    <Box className={classes.wrapper}>
      {
        // the google map
      }
      <GoogleMap onLoad={displayROI} style={{ position: "absolute" }} />
      {
        // the tour
      }
 
<Grow in={!isDrawing} unmountOnExit>
      <Grid
          container
          justifyContent="center"
          spacing={0}
          id="mapScreen"
          className={classes.mapOverlay}
        >         
            <Grid  item xs={9} className={classes.mapContainer}>       
            </Grid>          
            <Grid  item xs={3}>
              <LoadedImagesAccordion />
            </Grid>
        </Grid>
</Grow>

      <Grow in={!isDrawing} unmountOnExit>
        {
          // this grid is placed over the map to display the image chooser and the actions card
          // @TODO: remove this grid and use contextual menus instead
        }
        <Grid
          container
          justifyContent="left"
          spacing={0}
          id="mapScreen"
          className={classes.mapOverlay}
        >       
          <Grid item xs={3} className={classes.mapContainer}>
            <Card  style={{ margin: 12 }}> 
              <CardHeader
                title={
                  <div>
                    {t("forms.acquisition.5.bathymetry.bathymetry")}
                  </div>
                }
              />
              <Divider />
              <CardContent>
                <div>
                  {t("forms.acquisition.5.bathymetry.maxDepth")}:<br /> <span id="min"> --- </span>  {t("forms.acquisition.5.bathymetry.meters")}:
                  <br />
                  {t("forms.acquisition.5.bathymetry.minDepth")}:<br /> <span id="max"> --- </span> {t("forms.acquisition.5.bathymetry.meters")}
                  <br />
                  SLOPE:<br/><span id="slope"> --- </span>
                  <br />
                  Interception:<br/><span id="interception"> --- </span>
                  <br />           
                  RMSE:<br/><span id="rmse"> --- </span>
                  <br />
                  BIAS:<br/><span id="bias"> --- </span>
                  <br /><br />
                  <a href="" id="statistics_anchor" style={{display:"none"}}>{t("forms.acquisition.5.bathymetry.downloadStatistics")} (CSV)</a>
                  <br />
                  <a href="" id="depth_csv_anchor" style={{display:"none"}}>Download Bathymetry (CSV)</a>
                  <br />
                  <table>
                    <tbody>
                    <tr>
                      <td>
                          <img src={thumbURL}  width="200" height="250" />
                          <br />
                          <a href={thumbURL} target="_blank">{t("forms.acquisition.3.bathymetry.downloadImage")} (PNG/JPG)</a>
                          <br />             
                          <a onClick = {()=>window.open(BathymetryResults.downloadURLZip)} target="_blank" style={{cursor:"pointer", color:"blue"}}><u>{t("forms.acquisition.3.bathymetry.downloadImage")} (ZIP)</u></a>         
                      </td>                 
                      <td>
                          <PlotlyComponent className="whatever" style={{borderWidth: 2, borderColor: 'black', width:128}} data={plotlyData} layout={layout} config={config}/>
                      </td>                   
                    </tr>
                    </tbody>
                  </table>
                  <Button color="primary" variant="contained" className={classes.button} onClick= { returnToBegin } style={{cursor:"pointer"}} > {t("forms.acquisition.5.bathymetry.returnToBegin")}</Button>
                  <br />
                  </div>
              </CardContent>
            </Card>
          </Grid>     
        </Grid>
      </Grow>
    </Box>
  );
};
export default ProcessingBathymetryPage;
