import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ee from "../../../services/earth-engine";
import { Box, Button, Checkbox, Typography } from "@material-ui/core";
import StepperButtons from "../acquisition/StepperButtons";
import * as Map from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import { getMangroves } from "../../../algorithms/csqueeze";

// useStyles is a hook for styling this component with Material UI's styling solution

// This is the page of the second step of the aquisition wizard
// it is supposed to get the Area of Interest (AOI) from the map
// and to save it in the storage.
// It also shows a button to start the third step.
const AOIChooser = ({ navigate, ...extra }) => {
  const dispatch = useDispatch();
  const mapDiv = useRef();
  const mapRef = useRef();
  const drawingManager = useRef();
  const mangroveLayer = useRef();

  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [areaOfPolygon, setAreaOfPolygon] = useState(null);

  const [mangrove, setMangrove] = useState(false);

  // let saveZoomChange = function () {
  //   let zoom = mapRef.current.getZoom();
  //   if (typeof zoom !== "undefined" && zoom !== "undefined") {
  //     window.sessionStorage.setItem("zoom_level", zoom);
  //   }
  // };
  // window.sessionStorage.removeItem("zoom_level"); //clear previous saved zoom (aquisition -first use case)
  // setInterval(saveZoomChange, 5000);

  useEffect(() => {
    // const mangroves = getMangroves();
    // console.log("mangroves", mangroves);

    mapRef.current = new window.google.maps.Map(mapDiv.current, {
      center: { lat: -26.285757, lng: -48.73506 },
      zoom: 10,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      fullscreenControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    drawingManager.current = new window.google.maps.drawing.DrawingManager({
      // drawingMode: "rectangle",
      drawingControl: true,
      drawingControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      polygonOptions: {
        fillColor: "#00B3A1",
        fillOpacity: 0.2,
        strokeColor: "#003832",
        strokeOpacity: 1,
        strokeWeight: 0.5,
      },
    });

    drawingManager.current.setMap(mapRef.current);

    window.google.maps.event.addListener(
      drawingManager.current,
      "overlaycomplete",
      handleDrawing
    );

    // Map.setDrawingControlsVisible(true);

    // return () => {
    //   Map.onZoomChange(() => {
    //     saveZoomChange();
    //   });
    // };
  }, []);

  function getCoordinates(shape) {
    let coordinates = [];

    if (shape.type === "rectangle") {
      const bounds = shape.overlay.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      coordinates = [
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
      ];
    }

    if (shape.type === "polygon") {
      const path = shape.overlay.getPath();
      const array = path.getArray();
      coordinates = array.map((p) => [p.lng(), p.lat()]);
    }

    return coordinates;
  }

  //test default poligonon
  //https://geojson.io/
  /*
 let defaultCoordinates=[
  [-48.5841129271845, -26.19084941178084], 
  [-48.579735562072194, -26.193853088887273],
  [-48.58540038751165, -26.19400712152275],
  [-48.5841129271845, -26.19084941178084]];

  
  setTimeout(()=>{
    let overlayFixed=Map.drawOutline(defaultCoordinates);
    setOverlay(overlayFixed);
    setCoordinates(defaultCoordinates);
    Map.setDrawingControlsVisible(false);
  },3000);
*/
  // handle drawing the AOI on the map
  const handleDrawing = (overlay) => {
    let coords = getCoordinates(overlay);

    setOverlay(overlay.overlay);
    setCoordinates(coords);
    // disable the drawing controls, when the AOI is drawn
    drawingManager.current.setMap(null);

    // Calcule a área do polígono usando a função computeArea da biblioteca de geometria do Google Maps
    var areaM = window.google.maps.geometry.spherical.computeArea(
      coords.map((coord) => {
        return { lat: coord[0], lng: coord[1] };
      })
    );

    // Converta a área em metros quadrados para quilômetros quadrados
    var areaKm = areaM / 1000000;

    setAreaOfPolygon(areaKm);
  };

  // handle the undoing of the AOI
  const handleUndo = () => {
    overlay.setMap(null);
    setOverlay(null);
    setCoordinates(null);
    setAreaOfPolygon(null);
    // enable the drawing controls
    drawingManager.current.setMap(mapRef.current);
  };

  // handle the saving of the AOI
  const handleChoose = () => {
    dispatch(
      Acquisition.setAOI(
        overlay,
        coordinates,
        ee.Geometry.Polygon([coordinates])
      )
    );
  };

  const handleMangrove = (e) => {
    let visible = typeof e === "boolean" ? e : e.target.checked;
    if (!mangroveLayer.current) mangroveLayer.current = getMangroves();

    if (visible) {
      mapRef.current.overlayMapTypes.push(mangroveLayer.current);
    } else {
      mapRef.current.overlayMapTypes.clear();
    }

    setMangrove(visible);
  };

  // check if the AOI is already saved
  const drawn = Boolean(coordinates);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.csqueeze.2.aoi"),
    },
    {
      selector: "#mangrove",
      content: t("tour.csqueeze.2.mangrove"),
    },
    {
      selector: "#importKml",
      content: t("tour.csqueeze.2.importKml"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);

  const readFileTrainingData = function () {
    let fileField = document.getElementById("file_import_training_data");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        console.log("File data:", reader.result);
        const fileContent = reader.result;
        let lines = fileContent.split("\n");
        lines = lines.splice(1, lines.length);
        let matrix = [];
        lines.forEach((line) => {
          let columns = line.split(",");
          let x = Number.parseFloat(columns[0]);
          let y = Number.parseFloat(columns[1]);
          let z = Number.parseFloat(columns[2]);
          matrix.push([x, y, z]);
        });
        console.log("matrix", matrix);
        window.alert("Training file processed.");
        closeTrainingFilePanel();
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };

  const readFileData = function () {
    let fileField = document.getElementById("file_import_kml");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        console.log("File data:", reader.result);
        const fileContent = reader.result;
        const kmlTokenBegin = "<coordinates>";
        const kmlTokenEnd = "</coordinates>";
        let isKml = fileContent.indexOf(kmlTokenBegin) !== -1;
        if (isKml) {
          let coordinatesString = fileContent
            .split(kmlTokenBegin)[1]
            .split(kmlTokenEnd)[0];
          let coordinatesArrayStrings = coordinatesString
            .split(" ")
            .map((el) => el.trim());
          let coordinatesArray = [];
          coordinatesArrayStrings.forEach((el) => {
            if (el.trim() !== "") {
              let coordinatesValues = el.split(",");
              coordinatesValues = coordinatesValues.map((el) => parseFloat(el));
              if (!isNaN(coordinatesValues[0])) {
                coordinatesArray.push([
                  coordinatesValues[0],
                  coordinatesValues[1],
                ]);
              }
            }
          });
          //select area
          if (coordinatesArray.length > 0) {
            let overlayFixed = Map.drawOutline(coordinatesArray);
            setOverlay(overlayFixed);
            setCoordinates(coordinatesArray);
            Map.centralize(coordinatesArray[0][1], coordinatesArray[0][0]);
            Map.setDrawingControlsVisible(false);
            window.alert("KML file processed.");
            closeFilePanel();
          } else {
            window.alert("No valid coordinates were found in the KML file.");
          }
        } else {
          window.alert("It was not possible to interpret the selected file.");
        }
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };

  const handleDisplayImportKML = function () {
    let dialog = document.getElementById("dialog_kml");
    dialog.showModal();
  };

  const handleDisplayImportTrainingData = function () {
    let dialog = document.getElementById("dialog_training_csv");
    dialog.showModal();
  };

  const closeFilePanel = function () {
    let dialog = document.getElementById("dialog_kml");
    dialog.close();
  };

  const closeTrainingFilePanel = function () {
    let dialog = document.getElementById("dialog_training_csv");
    dialog.close();
  };

  const displayTrainingButton = function () {
    let url = window.location.href;
    if (url.indexOf("bathymetry") != -1) {
      return "inline-block";
    }
    return "none";
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          marginBottom={areaOfPolygon ? -9 : 0}
          marginTop={areaOfPolygon ? +3 : 0}
          style={{ color: "white" }}
        >
          {areaOfPolygon && (
            <React.Fragment>
              {areaOfPolygon > 20 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#f50057",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.slow").toUpperCase()
                    )}
                </Typography>
              ) : areaOfPolygon > 10 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#f44336",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.moderate").toUpperCase()
                    )}
                </Typography>
              ) : (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#009688",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.fast").toUpperCase()
                    )}
                </Typography>
              )}
            </React.Fragment>
          )}
        </Box>
        <Button
          id="importKml"
          onClick={handleDisplayImportKML}
          sx={{ boxShadow: 12 }}
          variant="outlined"
          style={{
            cursor: "pointer",
            color: "#26a69a",
            left: 10,
            top: 205,
            backgroundColor: "#FFF",
            zIndex: 99,
          }}
        >
          {t("forms.acquisition.2.importTitle")}
        </Button>
        <dialog id="dialog_kml">
          <fieldset
            style={{ borderColor: "#BBB", display: "block" }}
            id="import_kml_component"
          >
            <legend> {t("forms.acquisition.2.importLegend")}</legend>
            <input
              id="file_import_kml"
              accept=".kml"
              type="file"
              onChange={readFileData}
            />
          </fieldset>
          <Button
            color="secondary"
            variant="contained"
            onClick={closeFilePanel}
          >
            {t("forms.map.cancel")}
          </Button>
        </dialog>
        <Button
          onClick={handleDisplayImportTrainingData}
          style={{
            cursor: "pointer",
            display: displayTrainingButton(),
            color: "#26a69a",
            left: 675,
            top: 100,
            backgroundColor: "#FFF",
            zIndex: 99,
          }}
        >
          Data Training
        </Button>
        <dialog id="dialog_training_csv">
          <fieldset
            style={{ borderColor: "#BBB", display: "block" }}
            id="import_csv_component"
          >
            <legend> Import training data</legend>
            <input
              id="file_import_training_data"
              accept=".csv"
              type="file"
              onChange={readFileTrainingData}
            />
          </fieldset>
          <Button
            color="secondary"
            variant="contained"
            onClick={closeTrainingFilePanel}
          >
            {t("forms.map.cancel")}
          </Button>
        </dialog>
        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <Box
          id="mangrove"
          style={{
            height: "40px",
            position: "absolute",
            backgroundColor: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            borderRadius: "2px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            padding: "0px 10px 0px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "99",
            marginTop: "60px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          sx={{ boxShadow: 12 }}
          onClick={() => handleMangrove(!mangrove)}
        >
          <Checkbox
            checked={mangrove}
            // onClick={handleMangrove}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Typography>{t("forms.csqueeze.2.viewMangrove")}</Typography>
        </Box>
        <div id="areachooser" style={{ width: "100%", height: 600 }}>
          <div
            id="google-maps"
            ref={(r) => (mapDiv.current = r)}
            style={{ height: "100%" }}
          ></div>
        </div>
        <StepperButtons
          navigate={navigate}
          nextDisabled={drawn === false}
          onNext={handleChoose}
        >
          <Button
            onClick={handleUndo}
            disabled={drawn === false}
            color="secondary"
            variant="outlined"
          >
            {t("forms.acquisition.2.undo")}
          </Button>
        </StepperButtons>
        {
          // if the user hasn't seen the tour, show it
        }
        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default AOIChooser;
