import * as Algorithms from "../algorithms";
import sentinel2Thumb from "../resources/Sentinel-2.jpg";
import landsatThumb from "../resources/Landsat.jpg";

/* Single Satellite Mission Constructor */
const Mission = (
  name,
  shortname,
  cycle,
  startYear,
  endYear,
  opticalResolution,
  bands,
  vizParams,
  properties,
  algorithms,
  fallbackMission = null
) => {
  /* Unite params */
  const union = {
    name,
    shortname,
    cycle,
    startYear,
    endYear,
    opticalResolution,
    bands,
    vizParams,
    properties,
    fallbackMission,
  };

  /*
    Bind each algorithm to the collection (mission)
    as all of them are built on a currying manner.
    method = mission => (...) => {}
             ^^^^^^^^^^
  */
  const boundAlgorithms = {};
  Object.keys(algorithms).forEach((key) => {
    boundAlgorithms[key] = algorithms[key](union);
  });

  return { ...union, algorithms: boundAlgorithms };
};

/* Satellite Mission Collection */
const MissionCollection = (
  name,
  provider,
  image,
  missions,
  enabled = false
) => {
  /* Unite params */
  const union = {
    name,
    provider,
    image,
    missions,
    enabled,
  };

  /* Create a summary of this Mission Collection */
  const summary = {
    cycle: union.missions.reduce((last, mission) => mission.cycle, null),

    startYear: union.missions.reduce((last, mission) => {
      return last ? Math.min(last, mission.startYear) : mission.startYear;
    }, null),

    endYear: union.missions.some((mission) => mission.endYear === null)
      ? null
      : union.missions.reduce((last, mission) => {
        return last ? Math.max(last, mission.endYear) : mission.endYear;
      }, null),

    opticalResolution: union.missions.reduce(
      (last, mission) => mission.opticalResolution,
      null
    ),
  };

  /* Construct methods */
  const methods = {
    /* Retrieve mission by name */
    get: (name) => {
      const [first] = union.missions.filter((mission) => mission.name === name);
      return first;
    },
  };

  return { ...union, summary, ...methods };
};

export const fallback = {
  landsat5_t2_toa: Mission(
    "LANDSAT/LT05/C02/T1_TOA",
    "LT05",
    16,
    1984,
    2011,
    30,
    { red: "B3", green: "B2", blue: "B1", nir: "B4", swir: "B5", qa: "QA_PIXEL" },
    {},
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.LandsatTOA.queryAvailable,
      getAvailable: Algorithms.Satellite.LandsatTOA.getAvailable,
      acquire: Algorithms.Satellite.LandsatTOA.acquire,
    }
  ),
  landsat8_t2_toa: Mission(
    "LANDSAT/LC08/C02/T1_TOA",
    "LC08",
    16,
    2013,
    null,
    30,
    { red: "B4", green: "B3", blue: "B2", nir: "B5", swir: "B6", qa: "QA_PIXEL" },
    {},
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.LandsatTOA.queryAvailable,
      getAvailable: Algorithms.Satellite.LandsatTOA.getAvailable,
      acquire: Algorithms.Satellite.LandsatTOA.acquire,
    }
  ),
  landsat9_t2_toa: Mission(
    "LANDSAT/LC09/C02/T1_TOA",
    "LC09",
    16,
    2021,
    null,
    30,
    { red: "B4", green: "B3", blue: "B2", nir: "B5", swir: "B6", qa: "QA_PIXEL" },
    {},
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.LandsatTOA.queryAvailable,
      getAvailable: Algorithms.Satellite.LandsatTOA.getAvailable,
      acquire: Algorithms.Satellite.LandsatTOA.acquire,
    }
  )
};
console.log("standard-mission","Define mission for landsat...");
export const standard = [
  /* SENTINEL 2 */
  MissionCollection(
    "Sentinel-2",
    "ESA",
    sentinel2Thumb,
    [
      /* SENTINEL 2 */
      Mission(
        "COPERNICUS/S2_HARMONIZED",
        "S2",
        5,
        2013,
        null,
        10,
        { blue: "B2", green: "B3", red: "B4", nir: "B8", swir: "B11", qa: "QA60" },
        { max: 3000, min: 100 },
        { cloudCoverProperty: "CLOUDY_PIXEL_PERCENTAGE" },
        {
          queryAvailable: Algorithms.Satellite.Sentinel.queryAvailable,
          getAvailable: Algorithms.Satellite.Sentinel.getAvailable,
          acquire: Algorithms.Satellite.Sentinel.acquire,
        }
      ),
    ],
    true
  ),

  
  /* LANDSATS */
  MissionCollection(
    "Landsat",
    "USGS/NASA",
    landsatThumb,
    [fallback.landsat5_t2_toa, fallback.landsat8_t2_toa, fallback.landsat9_t2_toa],
    true
  ),
];

export const csqueeze = [
  /* SENTINEL 2 */
  MissionCollection(
    "Sentinel-2",
    "ESA",
    sentinel2Thumb,
    [
      /* SENTINEL 2 */
      Mission(
        "COPERNICUS/S2_SR_HARMONIZED",
        "S2",
        5,
        2013,
        null,
        10,
        { blue: "B2", green: "B3", red: "B4", nir: "B8", swir: "B11", qa: "QA60" },
        { max: 3000, min: 0 },
        { cloudCoverProperty: "CLOUDY_PIXEL_PERCENTAGE" },
        {
          queryAvailable: Algorithms.Satellite.Sentinel.queryAvailable,
          getAvailable: Algorithms.Satellite.Sentinel.getAvailable,
          acquire: Algorithms.Satellite.Sentinel.acquire,
        }
      ),
    ],
    true
  ),

  /* LANDSATS */
  MissionCollection(
    "Landsat",
    "USGS/NASA",
    landsatThumb,
    [
      /* LANDSAT 5 */
      Mission(
        "LANDSAT/LT05/C02/T1_L2", //"LANDSAT/LT05/C01/T1_SR"
        "LT05",
        16,
        1984,
        2012,
        30,
        {
          red: "SR_B3",
          green: "SR_B2",
          blue: "SR_B1",
          nir: "SR_B4",
          swir: "SR_B5",
          qa: "QA_PIXEL",
        },
        {
          min: 0.0,
          max: 0.3,
        },
        //gain: "0.1,0.1,0.1" },
        //{ gain: "10,10,10" },
        { cloudCoverProperty: "CLOUD_COVER" },
        {
          queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
          getAvailable: Algorithms.Satellite.Landsat.getAvailable,
          acquire: Algorithms.Satellite.Landsat.acquire,
        }
      ),
      Mission(
        "LANDSAT/LC08/C02/T1_L2",
        "LC08",
        16,
        2013,
        null,
        30,
        {
          red: "SR_B4",
          green: "SR_B3",
          blue: "SR_B2",
          nir: "SR_B5",
          swir: "SR_B6",
          qa: "QA_PIXEL",
        },
        {
          gain: "1,1,1"
        },
        // gain: "0.1,0.1,0.1" },
        //{ gain: "10,10,10" },
        { cloudCoverProperty: "CLOUD_COVER" },
        {
          queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
          getAvailable: Algorithms.Satellite.Landsat.getAvailable,
          acquire: Algorithms.Satellite.Landsat.acquire,
        }
      ),
      Mission(
        "LANDSAT/LC09/C02/T1_L2",
        "LC09",
        16,
        2013,
        null,
        30,
        {
          red: "SR_B4",
          green: "SR_B3",
          blue: "SR_B2",
          nir: "SR_B5",
          swir: "SR_B6",
          qa: "QA_PIXEL",//SR_CLOUD_QA
        },
        {
          gain: "1,1,1"
        },
        // gain: "0.1,0.1,0.1" },
        //{ gain: "10,10,10" },
        { cloudCoverProperty: "CLOUD_COVER" },
        {
          queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
          getAvailable: Algorithms.Satellite.Landsat.getAvailable,
          acquire: Algorithms.Satellite.Landsat.acquire,
        },
      ),
    ],
    true
  ),
];

let landsatMissionsWithoutTOA = [
  /* LANDSAT 5 */
  Mission(
    "LANDSAT/LT05/C02/T1_L2", //"LANDSAT/LT05/C01/T1_SR"
    "LT05",
    16,
    1984,
    2012,
    30,
    {
      red: "SR_B3",
      green: "SR_B2",
      blue: "SR_B1",
      nir: "SR_B4",
      swir: "SR_B5",
      qa: "QA_PIXEL",
    },
    {
      gain: "1,1,1"
    },
    //gain: "0.1,0.1,0.1" },
    //{ gain: "10,10,10" },
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
      getAvailable: Algorithms.Satellite.Landsat.getAvailable,
      acquire: Algorithms.Satellite.Landsat.acquire,
    }
  ),
  /*
  /* LANDSAT 7
  Mission(
    "LANDSAT/LE07/C02/T1_SR",
    'LE07',
    16,
    1999,
    null,
    30,
    { red: "B3", green: "B2", blue: "B1", nir: "B4", swir: "B5", qa: "pixel_qa" },
    { gain: "0.1,0.1,0.1" },
    { cloudCoverProperty: 'CLOUD_COVER' },
    {
      queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
      getAvailable: Algorithms.Satellite.Landsat.getAvailable,
      acquire: Algorithms.Satellite.Landsat.acquire
    },
    fallback.landsat7_t2_toa
  ),
  */
  /* LANDSAT 8 */
  Mission(
    "LANDSAT/LC08/C02/T1_L2",
    "LC08",
    16,
    2013,
    null,
    30,
    {
      red: "SR_B4",
      green: "SR_B3",
      blue: "SR_B2",
      nir: "SR_B5",
      swir: "SR_B6",
      qa: "QA_PIXEL",
    },
    {
      gain: "1,1,1"
    },
    // gain: "0.1,0.1,0.1" },
    //{ gain: "10,10,10" },
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
      getAvailable: Algorithms.Satellite.Landsat.getAvailable,
      acquire: Algorithms.Satellite.Landsat.acquire,
    },
    fallback.landsat8_t2_toa
  ),
  /* LANDSAT 9 */
  Mission(
    "LANDSAT/LC09/C02/T1_L2",
    "LC09",
    16,
    2013,
    null,
    30,
    {
      red: "SR_B4",
      green: "SR_B3",
      blue: "SR_B2",
      nir: "SR_B5",
      swir: "SR_B6",
      qa: "QA_PIXEL",//SR_CLOUD_QA
    },
    {
      gain: "1,1,1"
    },
    // gain: "0.1,0.1,0.1" },
    //{ gain: "10,10,10" },
    { cloudCoverProperty: "CLOUD_COVER" },
    {
      queryAvailable: Algorithms.Satellite.Landsat.queryAvailable,
      getAvailable: Algorithms.Satellite.Landsat.getAvailable,
      acquire: Algorithms.Satellite.Landsat.acquire,
    },
    fallback.landsat9_t2_toa
  ),

];
const missionsBatymetry = [ csqueeze[0], csqueeze[1] ];

export const get = (index) => {
  let info = null;
  if (window.location.href.indexOf("csqueeze") !== -1){
    info = csqueeze[index];
  }else if (window.location.href.indexOf("bathymetry") !== -1){
    info = missionsBatymetry[index];
  }else{
    info = standard[index];
  }
  return info;
};
