import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { first, last } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Card,
  Chip,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";

import StepperButtons from "../acquisition/StepperButtons";
import ActivityIndicator from "../core/ActivityIndicator.jsx";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import { Actions as CSqueeze } from "../../../store/ducks/csqueeze";
import { formatDate, useLocalStorage } from "../../../common/utils";
import { uniteMissionsDates } from "../../../common/algorithms";
import ReactGA from "react-ga";
import TourGuider from "../tour/TourGuider";
import moment from "moment";
import HelpButton from "../core/HelpButton";

// useStyles is a hook for styling this component with Material UI's styling solution
const useStyles = makeStyles((theme) => ({
  wrapper: {
    // marginTop: theme.spacing(2)
  },
  description: {
    marginTop: 10,
    marginBottom: 10,
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    height: 73,
  },
}));

const SelectDatesCSqueeze = (props) => {
  const navigate = props.navigate;
  // get available dates from the storage
  const datesAcquisition = useSelector(
    (state) => state.acquisition.availableDates,
    shallowEqual
  );

  const missionsSqueeze = useSelector(
    (state) => state.csqueeze.missions,
    shallowEqual
  );
  const datesSqueeze = useSelector(
    (state) => state.csqueeze.dates,
    shallowEqual
  );

  const [prevYearSelected] = useSelector((state) => state.csqueeze.years);

  // get available missions from the satellite
  const missions = useSelector(
    (state) => state.acquisition.missions,
    shallowEqual
  );
  // get the loading state from the redux store
  const working = useSelector((state) => state.common.working);

  const dispatch = useDispatch();
  // get the current language
  const [t] = useTranslation();
  // custom styles
  const classes = useStyles();
  // create a new state for the cloud coverage, starting with 100%
  const [cloudCoverage, setcloudCoverage] = useState(1);
  // create a new state for the time period
  const [period, setPeriod] = useState(["", ""]);
  // create a new state for the start and end date,
  // starting with the first available date and the last available date
  const [start, end] = period;

  // defines the steps for the tour
  const steps = [
    {
      selector: "#timeselector",
      content: t("tour.csqueeze.3.years"),
    },
  ];

  const [isTourOpen, setIsTourOpen] = useLocalStorage("showPeriodTour", true);

  const [availableYears, setAvailableYears] = useState([]);

  const [years, setYears] = useState([null, null]);
  const [yearOne, setYearOne] = useState("");
  const [yearTwo, setYearTwo] = useState("");

  useEffect(() => {
    if (!prevYearSelected) dispatch(Acquisition.loadAvailableImages());
  }, [dispatch]);

  /// parei aqui tem que ver o problema do dates... ele nao é criado no inicio
  useEffect(() => {
    if (
      datesAcquisition &&
      datesAcquisition.length > 1 &&
      datesAcquisition.length !== datesSqueeze.length
    ) {
      dispatch(CSqueeze.setDates(datesAcquisition));
      dispatch(CSqueeze.setMissions(missions));
    }
  }, [datesAcquisition]);

  useEffect(() => {
    if (datesSqueeze && datesSqueeze.length > 0) {
      setPeriod([first(datesSqueeze).date, last(datesSqueeze).date]);

      let filterDates = datesSqueeze.map((date) =>
        parseInt(date.date.slice(0, 4))
      );
      setAvailableYears([...new Set(filterDates)]);
      changeYear(0, filterDates[0]);
      changeYear(1, filterDates[filterDates.length - 1]);
    }
  }, [datesSqueeze]);

  const handleBack = () => {
    dispatch(CSqueeze.setYears([]));
    dispatch(CSqueeze.setDates([]));
    dispatch(CSqueeze.setMissions([]));
  };

  const handleNext = () => {
    // save the current state in the Google Analytics
    ReactGA.event({
      category: "Acquisition",
      action: "SetcloudCoverage",
      value: cloudCoverage,
    });
    ReactGA.event({
      category: "Acquisition",
      action: "SetPeriod",
      value: start + "-" + end,
    });

    dispatch(CSqueeze.setYears(years));
    dispatch(CSqueeze.setDates(datesSqueeze));
    dispatch(CSqueeze.setMissions(missionsSqueeze));
  };

  const changeYear = (index, value) => {
    let tempYears = years;
    tempYears[index] = value;
    setYears(tempYears);

    if (index == 0) setYearOne(value);
    if (index == 1) setYearTwo(value);
  };

  // show loading animation when is working
  if (working === true) {
    return <ActivityIndicator textual />;
  }

  return (
    <Box style={{ padding: "0 20px 0 20px" }}>
      <HelpButton
        onClickFunction={() => {
          setIsTourOpen(true);
        }}
      />
      <Box
        className={classes.wrapper}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {/* <Typography
          variant="caption"
          align="center"
          style={{ marginBottom: 20 }}
        >
          *Selecione periodos longos para um melhor resultado na análise .
        </Typography> */}

        <Box display="flex" style={{ gap: 10 }} id="timeselector">
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              1º {t("forms.csqueeze.3.durationYears.singular")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={yearOne}
              onChange={(r) => changeYear(0, r.target.value)}
            >
              {availableYears.map((year, i) => {
                return (
                  <MenuItem value={year} key={i}>
                    {year} (
                    {
                      datesSqueeze.filter((date) => date.date.includes(year))
                        .length
                    }{" "}
                    {t("forms.csqueeze.3.imageQuantity.plural")})
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">
              2º {t("forms.csqueeze.3.durationYears.singular")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={yearTwo}
              onChange={(r) => changeYear(1, r.target.value)}
            >
              {availableYears.map((year, i) => {
                return (
                  <MenuItem value={year} key={i}>
                    {year} (
                    {
                      datesSqueeze.filter((date) => date.date.includes(year))
                        .length
                    }{" "}
                    {t("forms.csqueeze.3.imageQuantity.plural")})
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <StepperButtons
          navigate={navigate}
          onBack={handleBack}
          onNext={handleNext}
        />

        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </Box>
  );
};

export default SelectDatesCSqueeze;
