import duckify from "../../tools/duckify";

export const namespace = "cassie";
export const store = "csqueeze";

export const Types = duckify(namespace, store, [
  "STUDY_DATA",
  "TRANSFORM_CLASS_TO_POLYGONS",
  "ACQUIRE_IMAGES_MEDIAN",
  "CLASS_LAND_COVERS",
  "CALCULE_BASE_LINE",
  "WATER_LAND_COVER",
  "TRANSECTS_COORDS",
  "TRANSECTS_RESULTS",
  "RESET_CLASS_LAND_COVERS",
  "RESET_WATER_LAND_COVER",
  "SET_YEARS",
  "SET_DATES",
  "SET_MISSIONS"
]);

export const Actions = {
  acquireImagesMedian: (years, elevation, ...extras) => ({
    type: Types.ACQUIRE_IMAGES_MEDIAN,
    payload: { years, elevation, extras },
  }),

  setYears: (years) => {
    return {
      type: Types.SET_YEARS,
      payload: { years },
    };
  },

  setDates: (dates) => {
    return {
      type: Types.SET_DATES,
      payload: { dates },
    };
  },

  setMissions: (missions) => {
    return {
      type: Types.SET_MISSIONS,
      payload: { missions },
    };
  },

  setStudyData: (studyData) => {
    return {
      type: Types.STUDY_DATA,
      payload: { studyData },
    };
  },

  transformClassToPolygons: (classification, imageProjection, year, type, AOICoords) => ({
    type: Types.TRANSFORM_CLASS_TO_POLYGONS,
    payload: { classification, imageProjection, year, type, AOICoords },
  }),

  setClassLandCovers: (year, classLandCovers) => {
    return {
      type: Types.CLASS_LAND_COVERS,
      payload: { year, classLandCovers },
    };
  },

  resetClassLandCovers: () => {
    return {
      type: Types.RESET_CLASS_LAND_COVERS,
    };
  },

  setWaterLandCover: (waterLandCover) => {
    return {
      type: Types.WATER_LAND_COVER,
      payload: { waterLandCover },
    };
  },

  resetWaterLandCover: () => {
    return {
      type: Types.RESET_WATER_LAND_COVER,
    };
  },

  setTransectsCoords: (transectsCoords) => {
    return {
      type: Types.TRANSECTS_COORDS,
      payload: { transectsCoords },
    };
  },

  setTransectsResults: (transectsResults) => {
    return {
      type: Types.TRANSECTS_RESULTS,
      payload: { transectsResults },
    };
  },

  calculeBaseLine: (baseLineCoords, transectsData, years) => {
    return {
      type: Types.CALCULE_BASE_LINE,
      payload: { baseLineCoords, transectsData, years },
    };
  },
};

export default Actions;
