import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { first, last } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Card,
  Chip,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";

import TimePeriodSelector from "./_TimePeriodSelector";
import StepperButtons from "../acquisition/StepperButtons";
import CloudSelector from "../acquisition/CloudSelector";
import ActivityIndicator from "../core/ActivityIndicator.jsx";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import { formatDate, useLocalStorage } from "../../../common/utils";
import { uniteMissionsDates } from "../../../common/algorithms";
import ReactGA from "react-ga";
import TourGuider from "../tour/TourGuider";
import moment from "moment";
import HelpButton from "../core/HelpButton";
import ImageTableCSqueeze from "./_ImageTableCSqueeze";
import ImageCSqueeze from "./_ImageCSqueeze";

import DoneIcon from "@material-ui/icons/Done";

// useStyles is a hook for styling this component with Material UI's styling solution
const useStyles = makeStyles((theme) => ({
  wrapper: {
    // marginTop: theme.spacing(2)
  },
  description: {
    marginTop: 40,
    marginBottom: 40,
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    height: 73,
  },
}));

// parse ISO date string to timestamp
const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);
const fromTimestamp = (timestamp) => moment(timestamp).toISOString();

// parse timestamps to ISO date string
const toISOString = (timestamp) => moment(timestamp).toISOString();

// This is the page of the third step of the aquisition wizard
// it is supposed to get a start and end date and a max cloud coverage
// and to save it in the storage.
const ImagesSelect = (props) => {
  const navigate = props.navigate;
  // get available dates from the storage

  const metadata = useSelector((state) => state.acquisition.metadata);

  // get available missions from the satellite

  const years = useSelector((state) => state.csqueeze.years);
  const dates = useSelector((state) => state.csqueeze.dates);
  const missions = useSelector((state) => state.csqueeze.missions);
  // get the loading state from the redux store
  const working = useSelector((state) => state.common.working);

  const dispatch = useDispatch();
  // get the current language
  const [t] = useTranslation();
  // custom styles
  const classes = useStyles();
  // create a new state for the cloud coverage, starting with 100%
  const [cloudCoverage, setcloudCoverage] = useState(1);
  // create a new state for the time period
  const [period, setPeriod] = useState(["", ""]);
  const [selectedDates, setSelectedDates] = useState([[], []]);
  // create a new state for the start and end date,
  // starting with the first available date and the last available date
  const [start, end] = period;
  const [initialMissions, setInitialMissions] = useState([]);
  const [dateRefiner, setDateRefiner] = useState([]);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#yearsButtons",
      content: t("tour.csqueeze.4.years"),
    },
    {
      selector: "#timeselector",
      content: t("tour.csqueeze.4.timeselector"),
    },
    {
      selector: "#viewImageButton",
      content: t("tour.csqueeze.4.viewImageButton"),
    },
    {
      selector: "#cloudselector",
      content: t("tour.csqueeze.4.cloudselector"),
    },
    {
      selector: "#tableImages",
      content: t("tour.csqueeze.4.tableImages"),
    },
  ];

  const [isTourOpen, setIsTourOpen] = useLocalStorage("showPeriodTour", true);

  const [selectImage, setSelectImage] = useState(false);

  const [availableYears, setAvailableYears] = useState([]);

  const [activeTab, setActiveTab] = useState(0);

  const [rebuildTable, setRebuildTable] = useState(0);

  const [nextDisabled, setNextDisabled] = useState(true);

  let flatten = [];

  useEffect(() => {
    if (availableYears.length === 0 && flatten.length > 0) {
      const finalFlatten = Array.from(
        new Set(flatten.map((f) => parseInt(f.slice(0, 4))))
      );
      setAvailableYears(finalFlatten);
    }
  }, [flatten]);

  useEffect(() => {
    if (dates && filteredFlatten) {
      setPeriod([first(dates).date, first(dates).date]);
    }
  }, [dates]);

  useEffect(() => {
    if (initialMissions.length == 0) {
      setInitialMissions(Object.assign(missions));
    }
  }, [missions]);

  useEffect(() => {
    if (initialMissions.length > 0) {
      let newStart = selectDates()[0].date;
      setPeriod([newStart, newStart]);
    }
  }, [cloudCoverage]);

  useEffect(() => {
    handleUndo();
    if (initialMissions.length > 0) {
      let newStart =
        filteredFlatten[0] !== undefined
          ? filteredFlatten[0]
          : years[activeTab];
      setPeriod([newStart, newStart]);

      setRebuildTable(rebuildTable + 1);
    }
  }, [activeTab]);

  useEffect(() => {
    const [arr1, arr2] = selectedDates;
    let primeiro = arr1.length < 1;
    let segundo = arr2.length < 1;
    let tempNext = primeiro || segundo;

    setNextDisabled(tempNext);
  }, [selectedDates]);

  // get all dates between the start and end date with a max cloud coverage
  const selectDates = () => {
    return uniteMissionsDates(initialMissions).filter(
      (entry) => entry.content <= cloudCoverage
    );
  };

  // handle the save of this state and go to the next step
  const handleViewImage = () => {
    setSelectImage(false);

    // get the start date
    let begin = new Date(start);
    // !important!
    // set the start date to be one day before
    // this is important to avoid a bug with the acquisition that wasn't getting the images of the day
    // this solution is not perfect but it works and doesn't break the acquisition since the aquisition isn't daily
    begin.setDate(begin.getDate() - 1);
    // transform the start date to ISO string again
    begin = toISOString(toTimestamp(begin));
    // get all the dates in the period

    const newDates = selectDates().filter(
      (entry) => entry.date >= begin && entry.date <= end
    );
    // save the dates reversed in the redux storage

    setDateRefiner(newDates);

    dispatch(Acquisition.setAvailableDates(newDates));
    dispatch(Acquisition.loadThumbnails());
    setSelectImage(true);
  };

  const handleAddImage = (image) => {
    if (
      !selectedDates[activeTab].find(
        (value) => value.date === image.date && value.name === image.name
      )
    ) {
      setSelectImage(false);
      let tempSelectedDates = [...selectedDates];
      tempSelectedDates[activeTab] = [...tempSelectedDates[activeTab], image];
      setSelectedDates(tempSelectedDates);

      setRebuildTable(rebuildTable + 1);
    }
  };

  const handleChangeImageTable = (index) => {
    let tempSelectedDates = [...selectedDates];
    tempSelectedDates[activeTab] = selectedDates[activeTab].filter(
      (date, key) => key !== index
    );
    setSelectedDates(tempSelectedDates);
    setRebuildTable(rebuildTable + 1);
  };

  const handleUndo = (e) => setSelectImage(false);

  const handleBack = () => {
    // save the current state in the Google Analytics
    ReactGA.event({
      category: "Acquisition",
      action: "SetcloudCoverage",
      value: cloudCoverage,
    });
    ReactGA.event({
      category: "Acquisition",
      action: "SetPeriod",
      value: start + "-" + end,
    });

    handleUndo();
    setSelectedDates([[], []]);
  };

  const handleNext = () => {
    // save the current state in the Google Analytics
    ReactGA.event({
      category: "Acquisition",
      action: "SetcloudCoverage",
      value: cloudCoverage,
    });
    ReactGA.event({
      category: "Acquisition",
      action: "SetPeriod",
      value: start + "-" + end,
    });

    // save the dates reversed in the redux storage

    // fazer

    const finalImages = selectedDates[0].concat(selectedDates[1]);

    dispatch(Acquisition.setAvailableDates(finalImages.reverse()));
  };

  // show loading animation when is working
  if (working === true && initialMissions.length == 0) {
    return <ActivityIndicator textual />;
  }

  flatten = selectDates().map((entry) => entry.date);

  const filteredFlatten = flatten.filter((f) =>
    f.includes(`${years[activeTab]}`)
  );

  return (
    <Box style={{ padding: "0 20px 0 20px" }}>
      <HelpButton
        onClickFunction={() => {
          setIsTourOpen(true);
        }}
      />
      <Box
        className={classes.wrapper}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          width="90%"
          display="grid"
          gridTemplateColumns="auto auto"
          style={{
            gap: 5,
            "&:lastChild": { gridColumn: "1 / 3" },
          }}
          fontSize="small"
          fontWeight={300}
          id="yearsButtons"
        >
          {years.map((year, i) => (
            <Button
              key={i}
              variant={i === activeTab ? "contained" : "outlined"}
              color={i === activeTab ? "primary" : "default"}
              onClick={(b) => setActiveTab(i)}
            >
              {selectedDates[i].length > 0 && <DoneIcon />} {year}
            </Button>
          ))}
        </Box>

        {filteredFlatten.length > 0 && (
          <Box className={classes.description} align="center">
            <Typography align="center">
              {t("forms.csqueeze.4.tabText")}
            </Typography>
            {/* <Chip
                label={formatDate(fromTimestamp(start))}
                color="primary"
                style={{ fontWeight: "bolder", fontSize: "large" }}
              /> */}
          </Box>
        )}

        {filteredFlatten.length > 0 ? (
          <TimePeriodSelector
            dates={filteredFlatten}
            start={start}
            end={end}
            onChange={(start, _) => setPeriod([start, start])}
            type={
              props.history.location.state
                ? props.history.location.state.algorithm
                : null
            }
          />
        ) : (
          <React.Fragment>
            <h3 style={{ marginTop: 30, marginBottom: 30 }}>
              {t("forms.csqueeze.4.emptyImages")}
            </h3>
          </React.Fragment>
        )}

        <div style={{ width: "80%", marginTop: 20 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <CloudSelector
              level={cloudCoverage}
              onChange={(cloudCoverage) => setcloudCoverage(cloudCoverage)}
            />

            <Typography align="center" style={{ fontSize: 12 }}>
              ({filteredFlatten.length}{" "}
              {filteredFlatten.length > 0
                ? t("forms.csqueeze.4.imageQuantity.plural")
                : t("forms.csqueeze.4.imageQuantity.singular")}{" "}
              {t("forms.csqueeze.4.with")} {"<="} {cloudCoverage * 100}%{" "}
              {t("forms.csqueeze.4.cloudPercentage")})
            </Typography>

            {filteredFlatten.length > 0 && (
              <Button
                id="viewImageButton"
                onClick={handleViewImage}
                variant="contained"
                color="primary"
                style={{ height: 35 }}
              >
                {t("forms.csqueeze.4.viewImage")}
              </Button>
            )}
          </Box>
        </div>

        {selectImage ? (
          <div style={{ width: "80%" }}>
            <ImageCSqueeze
              images={dateRefiner}
              metadata={metadata}
              isIncluded={selectedDates[activeTab].find(
                (value) =>
                  value.date === dateRefiner[0].date &&
                  value.name === dateRefiner[0].name
              )}
              limit={false}
              onAddImage={handleAddImage}
              onUndo={handleUndo}
            />
          </div>
        ) : null}

        <Box
          className={classes.description}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Typography align="center" style={{ textTransform: "capitalize" }}>
            {t("forms.csqueeze.4.imageQuantity.plural")}{" "}
            {t("forms.csqueeze.4.selected")} ({selectedDates[activeTab].length}
            ):
          </Typography>
        </Box>
        <Card variant="outlined" style={{ width: "80%" }} id="tableImages">
          <ImageTableCSqueeze
            images={selectedDates[activeTab]}
            onCheckboxChange={handleChangeImageTable}
            rebuild={rebuildTable}
          />
        </Card>

        <StepperButtons
          navigate={navigate}
          onBack={handleBack}
          onNext={handleNext}
          nextDisabled={nextDisabled}
        />

        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </Box>
  );
};

export default ImagesSelect;
