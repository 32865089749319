import { ee } from "../../services/earth-engine";
import { mergeProperties, retrieveExtremes, getDate } from "../utils";
import * as Metadata from "../../common/metadata";

const addGridPosition = (element) => {
  const image = ee.Image(element);
  const rawPosition = {
    path: image.get("WRS_PATH"),
    row: image.get("WRS_ROW"),
  };
  const position = ee
    .Number(rawPosition.path)
    .multiply(100)
    .add(ee.Number(rawPosition.row));

  return image.set({ [Metadata.GRID_POSITION]: position });
};

const sliceByRevisit = (collection, startingDate, days) => {
  const start = ee.Date(startingDate).update(null, null, null, 0, 0, 0);
  const end = start.advance(days, "day");

  return collection.filterDate(start, end);
};

export const acquireFromDate = (date, mission, geometry) => {
  console.log("ACQUIRE FROM DATE - LANDSAT", mission);
  const slice = sliceByRevisit(
    ee.ImageCollection(mission.name).filterBounds(geometry),
    date,
    mission.cycle
  );
  const mosaicked = slice.mosaic().clip(geometry);
  //mosaicked.copyProperties(source, properties, exclude)
  const image = mosaicked.set(mergeProperties(slice));
  return scoreClouds(image, geometry, mission.bands.qa);
};

export const maskLandsatImageMask = (img, mission) =>{
    let ms = mission;
    const bandsFromMission = ms.bands; 
    var ndwi_revise = (img.select([bandsFromMission.green]).subtract(img.select([bandsFromMission.nir]))).divide(img.select([bandsFromMission.green]).add(img.select([bandsFromMission.nir])));
    img = img.updateMask(ndwi_revise.gt(0));
    return img;
}

export const maskLandsatCloudsRatio = (img,geometry,bandName) => {
  return scoreClouds(img, geometry, bandName);
}

export const scoreClouds = (image, geometry, qa, bitCloud, bitShadow) => {
  // const cloud = "((b(0) >> 6) & 1)"; //C1
  const cloud = "((b(0) >> 3) & 1)"; //C2

  //const shadow = "((b(0) >> 3) & 1)"; //C1
  const shadow = "((b(0) >> 4) & 1)"; //C2 

  //const confidence = "((b(0) >> 8) & 1) && ((b(0) >> 9) & 1)"; //C1
  //const confidence = "((b(0) >> 8) & 3) && ((b(0) >> 9) & 3)"; //C2
  //const confidence = "((b(0) >> 8) & 3) && ((b(0) >> 9) & 3)  && ((b(0) >> 10) & 3)  && ((b(0) >> 11) & 3)"; //C2

  //const expr = `(${confidence} > 0) && (${cloud} || ${shadow})`;
  const expr = `(${cloud} || ${shadow})`;
  const filtered = image.select(qa).expression(expr);

  console.log("QA", qa);
  const imageArea = filtered.multiply(ee.Image.pixelArea());
  console.log("ImageArea", imageArea);
  const res = imageArea.reduceRegion({
    reducer: ee.Reducer.sum(),
    scale: 1000,
    maxPixels: 1e9,
    geometry: geometry,
  });
  console.log("res", res);
  const cloudyArea = res.get(qa);
  const ratio = ee.Number(cloudyArea).divide(geometry.area(1));
  return image.set("CLOUDS", ratio);
};

// Computes a list of valid dates in the region to be retrieved with acquireFromDate.
export const processCollection = (mission, geometry) => {
  const query = ee.ImageCollection(mission.name).filterBounds(geometry);

  const process = (available) => {
    // Retrieve the globally extreme dates (earliest and latest)
    const global = retrieveExtremes(available);

    // Compute the grid position of each image and sort in ascending order
    const enhanced = available
      .map(addGridPosition)
      .sort(Metadata.GRID_POSITION);

    // Retrieve the northeasternmost grid position within the specified bounds
    const northeasternPosition = ee
      .Image(enhanced.toList(1).get(0))
      .get(Metadata.GRID_POSITION);

    // Keep images in the slice where the satellite passed first (northeast)
    const filtered = enhanced.filter(
      ee.Filter.eq(Metadata.GRID_POSITION, northeasternPosition)
    );

    // Retrieve the extremes in the northeastern position
    const northeastern = retrieveExtremes(filtered);

    // Compute the difference in days between the earliest image in the
    // northeastern position and the globally earliest image
    const difference = northeastern.earliest
      .difference(global.earliest, "day")
      .abs();
    const remainder = ee.Number(difference).mod(mission.cycle);

    // The amount of days we need to go back is given by the reverse of the
    // difference, in terms of the duration of an orbit
    const step = ee.Number(mission.cycle).subtract(remainder);

    // Compute the date of the earliest possible image in the northeastern
    // position (whether or not it exists) by going back in time
    const earliestDate = global.earliest.advance(step.multiply(-1), "day"); // 1.21 gigawatts!

    // Compute the amount of complete orbital cycles between the earliest and
    // latest possible dates of the images in the northeastern position (whether
    // or not they exist)
    const completeCycles = ee
      .Number(earliestDate.difference(global.latest, "day").abs())
      .divide(mission.cycle)
      .ceil();

    // Generate slices of 16 (0, 16, 32, 48, ...), one for each complete cycle
    const additions = ee.List.sequence(0, null, mission.cycle, completeCycles);

    // Transform each slice into an empty image. If the slice contains at least
    // one image, we add metadata related to the correspondent orbital cycle,
    // to allow for filtering later
    const carriers = additions.map((increment) => {
      const startingDate = earliestDate.advance(increment, "day");
      const collection = sliceByRevisit(available, startingDate, mission.cycle);

      const empty = ee.Algorithms.IsEqual(collection.size(), 0);
      const properties = ee.Algorithms.If(
        empty,
        {},
        mergeProperties(collection)
      );

      return ee.Image(0).set(properties);
    });

    // Keep only the images whose combined geometries contain the AOI
    const valid = ee.ImageCollection.fromImages(carriers).filter(
      ee.Filter.contains(Metadata.FOOTPRINT, geometry)
    );

    // For now only the date of the slice is important.
    return ee.List(valid.toList(valid.size()).map(getDate));
  };

  return ee.List(
    ee.Algorithms.If(query.size().gt(0), process(query), ee.List([]))
  );
};

export const generateCloudMap = (dates, mission, geometry) => {
  const cloudList = ee.List(dates).map((date) => {
    const image = ee.Image(acquireFromDate(date, mission, geometry));
    return image.get("CLOUDS");
  });

  return cloudList;
};

const queryAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);

  const process = (available) => {
    const datesQuery = available.map((date) =>
      // Format Data as ISO standart formating and and UTC
      ee.Date(date).format(null, 'UTC')
    );
    const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

    return ee.Dictionary.fromLists(datesQuery, cloudQuery);
  };

  return ee.Dictionary(
    ee.Algorithms.If(query.size().gt(0), process(query), ee.Dictionary({}))
  );
};

const getAvailable = (mission) => (geometry) => {};

const acquire = (mission) => (date, geometry) => {
  
  return acquireFromDate(date, mission, geometry);
};

const format = (properties) => {
  return properties["system:time_start"] + " -- " + properties["cloud"];
};

export default {
  queryAvailable,
  getAvailable,
  acquire,
  format,
};
