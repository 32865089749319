import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { loadScript } from "../../../services/dynamic-script";

const useStyles = makeStyles((theme) => ({
  outputtext: {
    width: "100%",
    height: "fit-content",
    fontWeight: "bold",
    fontSize: "1em",
    backgroundColor: "rgba(128, 128, 128, 0.34)",
    borderRadius: 10,
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    margin: "auto",
    placeContent: "center",
    textAlign: "center",
  },
}));

const TransectResult = ({ data }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const chartsBar = useRef();
  const chartsTable = useRef();

  useEffect(() => {
    console.log("data", data, window.google.visualization)
    const chartsData = new window.google.visualization.arrayToDataTable([
      ...data,
    ]);

    getDrawChart(chartsData, chartsBar.current);
    getTableChat(chartsData, chartsTable.current);
  });

  function getTableChat(data, node) {
    const table = new window.google.visualization.Table(node);

    table.draw(data, {
      showRowNumber: true,
      width: "100%",
      height: "100%",
      responsive: true,
    });
  }

  function getDrawChart(data, node) {
    var view = new window.google.visualization.DataView(data);
    view.setColumns([
      0,
      1,
      {
        sourceColumn: 1,
        type: "number",
        role: "annotation",
      },
      2,
      {
        sourceColumn: 2,
        type: "number",
        role: "annotation",
      },
      3,
      {
        sourceColumn: 3,
        type: "number",
        role: "annotation",
      },
      4,
      {
        sourceColumn: 4,
        type: "number",
        role: "annotation",
      },
    ]);

    var options = {
      title: t("forms.csqueeze.result.chartsTitle"),
      width: 445,
      height: 250,
      bar: { groupWidth: "70%" },
      isStacked: "percent",
      legend: { position: "none" },
      hAxis: {
        minValue: 0,
        ticks: [0, 0.25, 0.5, 0.75, 1],
      },
      series: {
        0: { color: "#af2a2a" },
        1: { color: "#129912" },
        2: { color: "#687537" },
        3: { color: "#0000ff" },
      },
    };

    var chart = new window.google.visualization.BarChart(node);

    chart.draw(view, window.google.charts.Bar.convertOptions(options));

    node.style.overflow = "hidden";
  }

  function getSqueezeMessage(rows) {
    let diffMangrove = rows[3][3] - rows[2][3],
      divMangrove = rows[3][3] / rows[2][3],
      squeeze = diffMangrove < 0 ? (divMangrove - 1) * 100 : 0,
      outputtext = "";

    if (rows[2][3] > 0) {
      if (rows[2][1] > 0) {
        if (squeeze < 0) {
          if (squeeze <= -100)
            outputtext = t("forms.csqueeze.result.output.fullSqueeze");
          else
            outputtext = t("forms.csqueeze.result.output.squeeze").replace(
              "{number}",
              Math.abs(squeeze.toFixed(2))
            );
        } else outputtext = t("forms.csqueeze.result.output.noSqueeze");
        outputtext += ` ${t("forms.csqueeze.result.in")} ${parseInt(
          rows[3][0]
        )} ${t("forms.csqueeze.result.output.thisTransect")}`;
      } else outputtext = t("forms.csqueeze.result.output.noHuman");
    } else outputtext = t("forms.csqueeze.result.output.noMangrove");

    return outputtext;
  }

  return (
    <Box>
      <Box className={classes.outputtext}>{getSqueezeMessage(data)}</Box>
      <Box>
        <div ref={chartsBar} style={{ marginLeft: -35 }}></div>
        <div ref={chartsTable} style={{ marginLeft: 0 }}></div>
      </Box>
    </Box>
  );
};

export default TransectResult;
