import React, { useState } from "react";
import moment from "moment";
import lastItem from "lodash/last";
import { Slider, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Tick from "../acquisition/Tick";
import { sequence, formatDate } from "../../../common/utils";
import ColorConvert from "color-convert";

const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);
const fromTimestamp = (timestamp) => moment(timestamp).toISOString();

// useStyles is a hook for styling this component with Material UI's styling solution
const useStyles = makeStyles((theme) => {
  const { primary, secondary } = theme.palette;

  const priRgb = ColorConvert.hex.rgb(primary.main);
  const secRgb = ColorConvert.hex.rgb(secondary.main);

  return {
    wrapper: {
      width: "80%",
    },
    labels: {
      marginTop: 4,
      marginBottom: 30,
      justifyContent: "space-between",
    },
    container: {
      position: "relative",
    },
    label: {
      position: "absolute",
      top: 18,
    },
    sliderRail: {
      height: 6,
    },
    sliderMark: {
      height: 13,
      width: 4,
      backgroundColor: `rgba(${secRgb[0]}, ${secRgb[1]}, ${secRgb[2]}, 0.5)`,
      bottom: 6,
    },
    sliderThumb: {
      borderColor: primary.main,
      backgroundColor: primary.main,
      width: 22,
      height: 22,
      marginTop: -8,
      marginLeft: -11,
    },
    sliderTooltip: {
      zIndex: 8,
    },
  };
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#009688",
    color: "white",
    borderRadius: "30px",
    fontWeight: "bolder",
    fontSize: "large",
    padding: "5px 10px;",
    zIndex: 10,
  },
}))(Tooltip);

// main export
const TimePeriodSelector = ({
  start,
  end,
  dates = [],
  labels = 4,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const timestamps = dates.map(toTimestamp);
  const interval = (lastItem(timestamps) - timestamps[0]) / (labels - 1);

  start = !start ? timestamps[0] : toTimestamp(start);
  end = !end ? lastItem(timestamps) : toTimestamp(end);

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <HtmlTooltip
        open={open}
        placement="top"
        title={formatDate(fromTimestamp(timestamps[value]))}
        PopperProps={{style:{zIndex:0}}}
      >
        {children}
      </HtmlTooltip>
    );
  }

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    onChange(
      fromTimestamp(timestamps[newValue]),
      fromTimestamp(timestamps[newValue])
    );
  };

  return (
    <Box className={classes.wrapper} id="timeselector">
      <Slider
        value={value}
        valueLabelDisplay="on"
        ValueLabelComponent={ValueLabelComponent}
        track={false}
        step={1}
        min={0}
        max={timestamps.length - 1}
        marks
        onChangeCommitted={handleChange}
        classes={{
          mark: classes.sliderMark,
          rail: classes.sliderRail,
          thumb: classes.sliderThumb,
        }}
      />
      {/* <Box className={classes.labels} display="flex" alignItems="center">
        {sequence(labels).map((i) => (
          <Box
            key={i}
            className={classes.container}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Tick height={15} />
            <Typography className={classes.label} variant="body1">
              {formatDate(Math.round(timestamps[0] + interval * i))}
            </Typography>
          </Box>
        ))}
      </Box> */}
    </Box>
  );
};

export default TimePeriodSelector;
