import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StepperButtons from "./StepperButtons";
import { FINALIZE } from "../../pages/AcquisitionPage";

import { useLocalStorage } from "../../../common/utils";
import { Box } from "@material-ui/core";
import HelpButton from "../core/HelpButton";
import {BathymetryResults} from "../../pages/ProcessingBathymetryPage";
import { ee } from "../../../services/earth-engine";
import { Line, Scatter } from "react-chartjs-2";
import { get as getSatellite } from "../../../common/satellites";
// This is the page of the fourth step of the aquisition wizard
const BathymetryBandsAndAlgorithmSelection = ({ navigate }) => {
  
    //initialize bands names based on selected satelite
    let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
    let satellite = getSatellite(selectedSatelliteIndex);
    let ms = JSON.parse(window.sessionStorage.getItem("selectedMission")); //BathymetryResults.getSelectedSatellite().missions[0];   
    const bandsFromMission = ms.bands;
    if (BathymetryResults.bandsSelected.length < 2){
       const defaultBands = [bandsFromMission.green,bandsFromMission.nir];
       console.log("defaultBands", defaultBands);
       BathymetryResults.bandsSelected = defaultBands;
    }
    const blue = bandsFromMission.blue;
    const green = bandsFromMission.green;
    const red = bandsFromMission.red;
    const nir = bandsFromMission.nir;
    const swir = bandsFromMission.swir;


  const metadata = useSelector((state) => state.acquisition.metadata);
  const dates = useSelector((state) => state.acquisition.availableDates);

  const dispatch = useDispatch();
  // get the current language from the store
  const [t] = useTranslation();
  
  const [values, setValues] = useState([]);



  const [bands, setBands] = useState([green,nir]);

  const [everythingSelected, setEverythingSelected] = useState(false);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#column0",
      content: t("tour.acquisition.4.id"),
    },
    {
      selector: "#column1",
      content: t("tour.acquisition.4.clouds"),
    },
    {
      selector: "#column2",
      content: t("tour.acquisition.4.image"),
    },
    {
      selector: "#columnaction",
      content: t("tour.acquisition.4.action"),
    },
  ];
  // the initial state of the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage(
    "showImageListChooserTour",
    true
  );
  
  // the initial state of the of each image of the table
  const [selected, setSelected] = useState(dates.map(() => true));
  
  // the action to perform when the user clicks in one checkbox
  const handleChange = (index, checked) => {
    // set the state of the checkbox for the index
    const updated = update(selected, {
      [index]: { $set: checked },
    });
    // dispatch the action to update the state of the application
    setSelected(updated);
  };

  const handleEnableNextButton = function (){
    if(BathymetryResults.thresholdValue !== 0 && BathymetryResults.algorithmSelected !== ""){
      setEverythingSelected(true);
    }else{
      setEverythingSelected(false);
    }
  }

  // the action to perform when the user clicks in the finalize button
  const handleFinish = () => {
     //const filtered = dates.filter((image, i) => selected[i] === true);
     //dispatch(Acquisition.setAvailableDates(filtered));
  };

  const updateThresholdValue = function (){ 
    let value = document.getElementById("threshold_field").value;
    value = Number.parseFloat(value);
    if(isNaN(value)){
      throw "Error reading threshold value";
    }else{
      BathymetryResults.thresholdValue = value;
      document.getElementById("display_threshold").innerHTML = value;
      handleEnableNextButton();
    }
  };

  if(values.length === 0 || window.sessionStorage.getItem("reprocessChart") ==="1"){
    window.sessionStorage.removeItem("reprocessChart");
    let dialog = document.createElement("dialog");
    document.body.appendChild(dialog);
    dialog.innerHTML = t("forms.acquisition.5.bathymetry.processing");
    dialog.showModal();
    setTimeout(() => {
        let error = false;
        //let trainingData = BathymetryResults.getTrainingDataAsFeatureCollection();//BathymetryResults.getTrainingDataAsMatrix();
        let trainingDataMatrix = BathymetryResults.getTrainingDataAsMatrix();
        let img = BathymetryResults.getSelectedImage();//eeImageToProcess;

        function calculateReason(el) {
          let bandA = bands[0];
          let bandB = bands[1];
          return ee.Feature(el.geometry())
          .set('ndwi', ee.Feature(ee.Number(el.get(bandA)).subtract(el.get(bandB)).divide(ee.Number(el.get(bandA)).add(el.get(bandB)))))
          .set('ndi', ee.Feature(ee.Number(el.get(bandA)).subtract(el.get(bandB)).divide(ee.Number(el.get(bandA)).add(el.get(bandB)))))
          .set('ratio', ee.Feature(ee.Number(el.get(bandA)).multiply(1).log().divide(ee.Number(el.get(bandB)).multiply(1).log())))
        }
          
      function createPoints(el) {
        //console.log("Processing point", el.get('X'), el.get('Y'));
        return ee.Feature(ee.Geometry.Point([el.get('X'),el.get('Y')], BathymetryResults.getCoordinatesSystem()), {'depth': el.get('Z')})
      }

     // console.log("trainingData",trainingData.getInfo());
      console.log("Creating points....");
      
      var points = [];//trainingData.map(createPoints);
      
      trainingDataMatrix.forEach(row => {
        try{
          if(!(row[0] === null || row[1] === null || row[2] === null)){
            let featurePoint = ee.Feature(ee.Geometry.Point([row[0],row[1]], BathymetryResults.getCoordinatesSystem()), {'depth': row[2]});
            points.push(featurePoint);
          }
        }catch(e){
          console.log("Error on line", row, e);
        }
      });
      
      console.log("points created.....");
      //console.log("points as array. Transforming in FeatureCollection",points);
      //console.log("first point", points[0].getInfo());
      try{
        points = ee.FeatureCollection(points);
        //console.log("fc", points);
        //console.log("points.getInfo()(fc)", points.getInfo());
        //console.log("geometry", BathymetryResults.geometry);
        var pointFilter = points.filterBounds(BathymetryResults.geometry);
        //console.log("pointFilter",pointFilter);
        //console.log("pointFilter.getInfo()",pointFilter.getInfo());
      }catch(e){
        dialog.close();
        error = true;
        console.log("e");
        window.alert ("An error has happened when applying the funciton .filterBounds over the points informed in your trainning data related to the selected area of interesst.\nPlease review the compatibility.");
      }
      if(!error){
        try{
          var pointData = img.reduceRegions({
            collection: pointFilter,
            crs: ee.Projection(BathymetryResults.getCoordinatesSystem()), //'EPSG:32722'
            scale: 10,
            reducer: ee.Reducer.median()
          });
          pointData = pointData.filter(bandsFromMission.blue + ' != null');//(ex. bandsFromMission.blue => B1 or SR_B1, etc.).
        }catch(e){
          dialog.close();
          error = true;
          console.log("e");
          window.alert ("An error has happened when processing the reduce regions operation using the selected image.\nMaybe you should try a different image.");
        }
        if (!error){
            console.log("reason calculated....");
            console.log("point data - reduce regions", pointData);
            //var points = trainingData.map(createPoints);
            pointData = pointData.map(calculateReason);
            console.log("points created.....");
            console.log("points as array",points  );
            //pointData  = ee.FeatureCollection(points );       
            //console.log("pointData calculate reason",pointData );
            //console.log("pointData calculate reason .getInfo()",pointData.getInfo() );
            error = false;
            let bandRatioValues = null;
            try{
              bandRatioValues = pointData.getInfo();
            }catch(e){
              window.alert("An error has happened when calculating the NDWI.\nIt could be related to the coordinates system and the training data compatibility or the quality of selected image in previous step.\nPlease retry.");
              console.error(e);
              dialog.close();
              error = true;
            }
            if(!error){
                console.log("pointData NDWI", pointData, bandRatioValues,bandRatioValues.features.length);
                let vals = [];
                const bandsRatio = ["ratio","ndwi", "ndi"];
                console.log("trainingDataMatrix fill graph data....", trainingDataMatrix);
                bandsRatio.forEach(bandRatio => {
                  graphData = [];
                  trainingDataMatrix.forEach(p => {
                    for(let i = 0; i < bandRatioValues.features.length; i++){
                        if(bandRatioValues.features[i].geometry.coordinates[0] === p[0] && bandRatioValues.features[i].geometry.coordinates[1] === p[1]){
                          graphData.push([p[2], bandRatioValues.features[i].properties[bandRatio] ]);
                          break;
                        }
                    }
                  });
                  console.log("graphData (complete)", graphData); 
                  let xValues =  graphData.map(el => el[0]);
                  let yValues = graphData.map(el => el[1]);
                  let min = Math.min(...xValues);
                  let max = Math.max(...xValues);
                  console.log("min and max....");
                  let dataScatter = graphData.map( el => {
                    return { "x": el[1], "y": el[0]}
                  });
                  vals.push( ...[graphData,xValues, yValues, min, max, dataScatter ]);
                });
                console.log("Set values....", vals);
                setValues(vals);
                dialog.close();
            }
          }
        }
      },1000);
      
  }
  let graphData = [];
  let xValues =  [];
  let yValues = [];
  let min = 0;
  let max = 1
  let dataScatter = [];
  if(values.length > 0 ){
    graphData = values[0];
    xValues =  values[1];
    yValues = values[2];
    min = values[3];
    max = values[4];
    dataScatter = values[5];
  } 
  console.log("graph values update", values);
  let scatterChartDataRATIO = {
    datasets: [
      {
        label: 'RATIO / Depth | pSDB = ln(BandA) / ln(BandB)',
        data: dataScatter,
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  let scatterChartDataNDWI = {
    datasets: [
      {
        label: 'NDWI / Depth  | NDI = (BandB - BandA) / (BandB + BandA)',
        data: values[11],
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };


let scatterChartDataNDI = {
  datasets: [
    {
      label: 'NDI / Depth',
      data: values[17],
      backgroundColor: 'rgba(255, 99, 132, 1)',
    },
  ],
};

let scatterChartOptions = {
  scales: {
    y: {
      beginAtZero: false,
    },
  },
};


const reprocessCharts = function(){
  let field = document.getElementsByName("bandA");
  let newBands = [];
  field.forEach(el=>{
    if(el.checked) newBands.push(el.value);
  });
  field = document.getElementsByName("bandB");
  field.forEach(el=>{
    if(el.checked) newBands.push(el.value);
  });

  if(newBands.length !== 2){
    window.alert("You must select exactly two bands.");
  }else{
    BathymetryResults.bandsSelected = newBands;
    window.sessionStorage.setItem("reprocessChart","1");
    setBands(newBands);
  }
 }
 
const selectAlgoritm1 = function(){
 let divSelected = document.getElementById("div_graph_1");
 console.log("Clicked", divSelected);
 BathymetryResults.algorithmSelected = "ratio";
 divSelected.style.border = "4px solid red";
 document.getElementById("div_graph_2").style.border="";
 handleEnableNextButton();
}

const selectAlgoritm2 = function(){
  let divSelected = document.getElementById("div_graph_2");
  console.log("Clicked", divSelected);
  BathymetryResults.algorithmSelected = "ndi";
  divSelected.style.border = "4px solid red";
  document.getElementById("div_graph_1").style.border="";
  handleEnableNextButton();
 }


  return (
    <Box>
      <HelpButton
        onClickFunction={() => {
          setIsTourOpen(true);
        }}
      />
      <div style={{marginLeft:30}}>
        
        <br/><span ><b>{t("forms.acquisition.4.bathymetry.bandsSelection")}</b>:</span> <br />
        <span>{t("forms.acquisition.4.bathymetry.band")} A:</span>
        <input type="radio" name="bandA" value={blue} /> {blue} (blue)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandA" value={green} /> {green} (green)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandA" value={red} /> {red} (red)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandA" value={nir} /> {nir} (nir)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandA" value={swir} /> {swir} (swir) &nbsp;&nbsp;&nbsp;
        <br />
        <span>{t("forms.acquisition.4.bathymetry.band")} B:</span>
        <input type="radio" name="bandB" value={blue} /> {blue} (blue)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandB" value={green} /> {green} (green)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandB" value={red} /> {red} (red)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandB" value={nir} /> {nir} (nir)&nbsp;&nbsp;&nbsp;
        <input type="radio" name="bandB" value={swir} /> {swir} (swir) &nbsp;&nbsp;&nbsp;
        <br />
        <Button  variant="contained"  onClick= { reprocessCharts } style={{cursor:"pointer"}} > {t("forms.acquisition.4.bathymetry.updateCharts")} </Button>
                  
        <br /><br />
     
        <div align="center"><b>{t("forms.acquisition.4.bathymetry.algorithmSelectionHint")} </b></div>
        <div id="div_graph_1" onClick={selectAlgoritm1}>
          <Scatter  options={scatterChartOptions} data={scatterChartDataRATIO} />
        </div>
        <br /><br />
        <div id="div_graph_2" onClick={selectAlgoritm2}>
          <Scatter options={scatterChartOptions} data={scatterChartDataNDWI} />
        </div>
   
        <div style={{display:"none"}}>
          <Scatter options={scatterChartOptions} data={scatterChartDataNDI} />
        </div>

        <br /><br />
        <div width="90%" align="center">
          <b>{t("forms.acquisition.4.bathymetry.thresholdSelection")}</b>:
          </div>
          <div width="90%" align="center">
            {min}<input style={{"width":"80%"}} type="range" min={min} max={max}  step="0.01" id="threshold_field" onInput={updateThresholdValue} /> {max}   
            <br />
          </div>
        <div align="center" width="90%" id="display_threshold"></div>

       
        <StepperButtons
          navigate={navigate}
          nextText={t("forms.acquisition.4.next")}
          onNext={handleFinish}
          nextDisabled={!everythingSelected}
          nextTarget={FINALIZE}
        />
       
      </div>
    </Box>
  );
};
export default BathymetryBandsAndAlgorithmSelection;
