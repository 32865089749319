import { Types } from "./header";
import { bufferedHandler, evaluate } from "../../tools/effects";
import { all, select, put, delay } from "redux-saga/effects";
import { ee } from "../../../services/earth-engine";
import { Actions as Imagery } from "../imagery";
import {
  applyScaleFactors,
  applyUiBand,
  applyNdviBand,
  applyMndwiBand, filterElevation, maskCloudLandsat, maskCloudSentinel, getLandCoverLabelMapBiomas
} from "../../../algorithms/csqueeze"
import { Actions as CSqueeze } from "./index";
import {
  getAcquisitionParameters,
} from "../../../selectors";
import { acquireFromDate } from "../../../algorithms/acquisition";




const handleTransformClassToPolygons = function* ({
  payload: { classification, imageProjection, year, type, AOICoords },
}) {
  var AOI = ee.Geometry.Polygon(AOICoords);

  let polyggonized, reduceParams = {
    geometry: AOI,
    scale: 30,
    eightConnected: true,
    crs: imageProjection,
  };

  if (type == "mapbiomas") {
    reduceParams = {
      reducer: ee.Reducer.countEvery(),
      geometry: AOI,
      scale: 30,
      maxPixels: 1e8
    };

    polyggonized = classification.reduceToVectors(reduceParams);

    var polyggonizedList = { ...polyggonized.getInfo() };

    polyggonizedList.features.forEach((feature, i) => {
      var label = feature.properties.label;

      polyggonizedList.columns.label = "Integer";
      polyggonizedList.features[i].properties.label = getLandCoverLabelMapBiomas(label)
    })

    polyggonized = ee.FeatureCollection(polyggonizedList);
  } else {
    polyggonized = classification.reduceToVectors(reduceParams);
  }

  let justWater = polyggonized.filter(ee.Filter.eq("label", 2));

  let landCoversCode = polyggonized.distinct("label").aggregate_array("label").getInfo();

  let landCoversGeometry = landCoversCode.map((code) => {
    return {
      id: code,
      year,
      geometry: polyggonized.filter(ee.Filter.eq("label", code)).geometry().getInfo()
    };
  });

  let waterOutPut = {
    year,
    geometry: justWater.geometry().getInfo()
  };

  yield put(CSqueeze.setWaterLandCover(waterOutPut));
  yield put(CSqueeze.setClassLandCovers(year, landCoversGeometry));
};


const handleAcquireImagesMedian = function* ({ payload: { years, elevation } }) {

  const { geometry, satellite } = yield select(getAcquisitionParameters);
  const layers = years.map((data) => {
    const year = data[0];
    const infos = data[1];

    let mission, missionInfo, medianImage, medianImageVisualize, falseColorVisualize, missionName;

    let imagesEE = ee.List(infos.map((info) => {
      const date = info.date;
      let end = new Date(date);
      end.setDate(end.getDate() + 1);
      // let end = new Date().toISOString()

      missionName = info.name;

      mission = satellite.get(missionName);

      let image = ee.ImageCollection(missionName)
        .filterBounds(geometry)
        .filter(ee.Filter.date(date, end))
        .first();

      // let image = acquireFromDate(date, missionName, geometry);

      missionInfo = missionName.split("/");

      return image;
    }));

    medianImage = ee.ImageCollection.fromImages(imagesEE)
    medianImage = medianImage.filterBounds(geometry);

    medianImage = medianImage.map((img) => {
      return img.addBands(applyUiBand(img, mission.bands))
        .addBands(applyNdviBand(img, mission.bands))
        .addBands(applyMndwiBand(img, mission.bands))
    })

    let bands = [mission.bands.red, mission.bands.green, mission.bands.blue];
    let bandsFalseColor = [mission.bands.nir, mission.bands.green, mission.bands.blue];

    if (missionInfo[0] === "LANDSAT") medianImage = medianImage.map(applyScaleFactors);
    medianImage = medianImage
      .map((img) => filterElevation(img, elevation, null, mission.shortname))
      .map((img) => {
        if (mission.shortname == "S2") {
          return maskCloudSentinel(img, mission)
        }

        return maskCloudLandsat(img, mission.bands.qa, geometry)

      }).mosaic().clip(geometry);

    let visualization = {
      bands: bands,
      min: 0.0,
      max: 0.3
    };

    medianImageVisualize = medianImage.visualize(visualization);

    if (missionInfo[0] === "LANDSAT") {
      medianImageVisualize = medianImageVisualize.rename(bands);
    }

    var url = medianImageVisualize.getThumbURL({
      format: 'png',
      dimensions: 800,
      tileScale: 512
    });

    let visualizationFalseColor = {
      bands: bandsFalseColor,
      min: 0.0,
      max: 0.3
    };

    falseColorVisualize = medianImage.visualize(visualizationFalseColor);

    if (missionInfo[0] === "LANDSAT") {
      falseColorVisualize = falseColorVisualize.rename(bands);
    }

    var falseColorUrl = falseColorVisualize.getThumbURL({
      format: 'png',
      dimensions: 800,
      tileScale: 512
    });


    return {
      url,
      falseColorUrl,
      year,
      mission,
      imageObject: medianImage,
      classification: null
    };

  })

  for (let i = 0; i < layers.length; i++) {
    const obj = layers[i];

    yield put(
      Imagery.pushImageUrl(obj.year, obj.year, obj.mission.name, obj.url, obj.falseColorUrl, obj.imageObject)
    );
    yield delay(1000);

  }

};

const root = function* () {
  yield all([
    bufferedHandler(
      Types.TRANSFORM_CLASS_TO_POLYGONS,
      handleTransformClassToPolygons
    ),
    bufferedHandler(Types.ACQUIRE_IMAGES_MEDIAN, handleAcquireImagesMedian),
  ]);
};

export default root;
