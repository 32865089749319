import { Types } from "./header";

const INITIAL_STATE = {
  studyData: [],
  classLandCoversVectors: [],
  waterLandCoverVectors: [],
  transectsCoords: [],
  transectsResults: [],
  years: [],
  dates: [],
  missions: []
};

export const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.SET_YEARS: {
      const { years } = payload;
      return {
        ...state,
        years,
      };
    }
    case Types.SET_DATES: {
      const { dates } = payload;
      return {
        ...state,
        dates,
      };
    }
    case Types.SET_MISSIONS: {
      const { missions } = payload;
      return {
        ...state,
        missions,
      };
    }
    case Types.STUDY_DATA: {
      const { studyData } = payload;
      return {
        ...state,
        studyData,
      };
    }
    case Types.CLASS_LAND_COVERS: {
      const { classLandCovers, year } = payload;
      return {
        ...state,
        classLandCoversVectors: { ...state.classLandCoversVectors, [year]: classLandCovers },
      };
    }
    case Types.RESET_CLASS_LAND_COVERS: {
      return {
        ...state,
        classLandCoversVectors: [],
      };
    }
    case Types.WATER_LAND_COVER: {
      const { waterLandCover } = payload;
      return {
        ...state,
        waterLandCoverVectors: [...state.waterLandCoverVectors, waterLandCover],
      };
    }
    case Types.RESET_WATER_LAND_COVER: {
      return {
        ...state,
        waterLandCoverVectors: [],
      };
    }
    case Types.TRANSECTS_COORDS: {
      const { transectsCoords } = payload;
      return {
        ...state,
        transectsCoords,
      };
    }
    case Types.TRANSECTS_RESULTS: {
      const { transectsResults } = payload;
      return {
        ...state,
        transectsResults,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
