import { all, call, put, select, takeLeading } from "redux-saga/effects";
import i18n from "i18next";
import { loadScript } from "../../../services/dynamic-script";
import { ee, initializeEEConnection } from "../../../services/earth-engine";
import * as Auth from "./header";
import { Actions as Snack } from "../snacks";


const AUTH_PARAMS = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  scope:
    "https://www.googleapis.com/auth/earthengine.readonly" /*ee.apiclient.AUTH_SCOPE + ${ee.apiclient.STORAGE_SCOPE} */,
};

/**
 * Funtions added to new authentication from google 2023
 */
let client = null; 
let access_token = null; 
let userData = null;
let callBackLogin = null;
function getToken(callback){
  callBackLogin = callback;
  client.requestAccessToken();
}

function revokeToken() {
  window.google.accounts.oauth2.revoke(access_token, () => {console.log('access token revoked')});
}
/**
 * Asynchronously loads and initiates auth2 client.
 * @returns {Promise}
 */
const gapiLoadAuth2 = async () => {
  return new Promise((resolve, reject) => {
    console.log("Auth - gapiLoadAuth2",AUTH_PARAMS);
    //https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#gapi-asyncawait
    //https://developers.google.com/identity/oauth2/web/guides/migration-to-gis
    loadScript("gsi library", "/client.js",()=>{// "//accounts.google.com/gsi/client"
      console.log("gsi library - Library loaded....");
      client = window.google.accounts.oauth2.initTokenClient({
        client_id: AUTH_PARAMS.client_id, //YOUR_CLIENT_ID',
        scope: AUTH_PARAMS.scope,  
        callback: async (tokenResponse) => {
          access_token = tokenResponse.access_token;
          console.log("Token", access_token, tokenResponse);
          console.log("oauth2", window.google.accounts.oauth2);
          window.document.cookie = `login=${access_token}`;
          
          try{
            let GoogleUserInfo = await window.fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`);
            const googleUser = await GoogleUserInfo.json();
            //console.log("GoogleUserInfo", googleUser);
            const picture = googleUser.picture;
            const email = googleUser.email;
            const userName = googleUser.name;
            userData = {picture, email, userName}
            console.log("userData", userData);
          }catch (e){
            console.warm("user data not loaded", e);
            userData = {picture:"", email:"", userName:""};
          }


            // Autentica no GEE com o token obtido do GSI
            ee.data.setAuthToken(
            client,
            'Bearer',
            access_token,
            3600*6 ,//(3600 = 1h)
            [],
            () => {
                ee.initialize(null, null, function(e) {
                    console.log("success - ee.initialize", e);
                }, function(e) {
                    console.log("error - ee.initialize", e);
                });
                let fn = callBackLogin();
                fn.next(); 
                fn.next();
                fn.next();
                fn.next();
                fn.next();
                resolve();
            },
            (error) => {
                console.log("error setting auth token", error);
                reject(error);
            }
        );

          /*
          ee.data.authenticate(()=> {//https://developers.google.com/earth-engine/custom-apps/client-js
            ee.initialize(null,null, function(e){
            },function(e){
          })}, (e)=> console.log("error authenticating", e));

          let initGEE = function (){
            ee.initialize(null, null, ()=>{console.log("gee callback: ok")}, (p)=>{console.log("gee callback: not ok",p)}, null, "cassie-dev");
          }
          ee.data.authenticateViaPopup(initGEE, (p1)=>{console.log("gee login fail...", p1)});
          ee.data.authenticateViaOauth( 
            // The OAuth Client ID defined above.
            AUTH_PARAMS.client_id,
            // Callback invoked immediately when user is already signed in.
            initGEE,
            // Show authentication errors in a popup.
            alert,
            // Request permission to only read and compute Earth Engine data on behalf of
            // user.
            [AUTH_PARAMS.scope],///extraScopes = 
            // Show sign-in button if reusing existing credentials fails.
            () => console.log("hide button...."),
            // Don't require ability to write and access Cloud Platform on behalf of the
            // user.
              true
          );
            */
          /*
          console.log("Callback new login GSI...");  //go on with after authentication functionalities
          let fn = callBackLogin();
          fn.next();
          fn.next();
          fn.next();
          fn.next();
          fn.next();
          */
        },
      });
  }
)();
    //old code
    /*
    loadScript(
      "gapi-client-auth",
      "//apis.google.com/js/api:client.js",
      () => {
       window.gapi.load("client:auth2", () => {
          window.gapi.auth2.init(AUTH_PARAMS).then((e) => resolve(e));
        }); 
      },
      (error) => reject(error)
    );
    */
  });
};

const loadClientAuth = function* ({ payload: { onSuccess } }) {
  try {
    yield call(gapiLoadAuth2);
    yield put(Auth.Actions.loadClientAuthSuccess());
    onSuccess();
  } catch (e) {
    yield put(Auth.Actions.loadClientAuthFailure(e));
  }
};

const loadClientAuthSuccess = function* () {
  // Load success
};

// eslint-disable-next-line require-yield
const loadClientAuthFailure = function* () {
  // Display notification
  //console.error("[ducks/auth] LOAD ERROR: Not able to load client:auth2");
};

const begin = function* ({ payload: { callback } }) {
  //if (client ===  null || access_token === null) {//old code: window.gapi && window.gapi.auth2
   // getToken();
  //}else{
    const signedUser = yield select((state) => state.auth.user);

    if (!signedUser) {
      //yield put(Snack.task("signin-task", i18n.t("auth.loading")));

      const instance = null; // old code: window.gapi.auth2.getAuthInstance();

      try {
        //if (!instance.isSignedIn.get()) {
         // yield call([instance, instance.signIn]);
        //}

        let callBackLogin = function* (){
          yield put(Auth.Actions.authorize(userData.userName, userData.picture));
          yield call(initializeEEConnection, process.env.REACT_APP_CLIENT_ID);
          yield put(Snack.dismiss("signin-task"));
          callback();
        };

        if (access_token === null ){
          console.log("callBackLogin", callBackLogin);
          getToken(callBackLogin);
        }
        
        //yield call(initializeEEConnection, process.env.REACT_APP_CLIENT_ID);
        /*
        const user = null; //old code: //window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
        yield put(Auth.Actions.authorize(userData.userName, userData.picture));
        yield put(Snack.dismiss("signin-task"));
        callback();
        */
      } catch (e) {
        yield put(Auth.Actions.authorizeFailure(e));
      }
    } else {
      yield put(Auth.Actions.authorize(signedUser.name, signedUser.imageUrl));
    }
 // }
};

const authorizeFailure = function* ({
  payload: {
    error: { error },
  },
}) {
  console.error(`[ducks/auth] AUTH ERROR: Not able to sign in due to ${error}`);

  yield put(Snack.error("signin-task-error", `Error: ${error}`));
  yield put(Snack.dismiss("signin-task"));
};

// eslint-disable-next-line require-yield
const revoke = function* ({ payload: { callback } }) {
  if (true) {//old code: //window.gapi && window.gapi.auth2
    revokeToken();//old code: window.gapi.auth2.getAuthInstance().disconnect();
    ee.data.clearAuthToken();
    callback();
  }
};

const root = function* () {
  yield all([
    takeLeading(Auth.Types.LOAD_CLIENT_AUTH, loadClientAuth),
    takeLeading(Auth.Types.LOAD_CLIENT_AUTH_SUCCESS, loadClientAuthSuccess),
    takeLeading(Auth.Types.LOAD_CLIENT_AUTH_FAILURE, loadClientAuthFailure),
    takeLeading(Auth.Types.BEGIN, begin),
    takeLeading(Auth.Types.AUTHORIZE_FAILURE, authorizeFailure),
    takeLeading(Auth.Types.REVOKE, revoke),
  ]);
};

export default root;
