import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Card, CardActions, CardContent, CardMedia } from "@material-ui/core";

// useStyles is the hook to Material-UI's style.
const useStyles = makeStyles((theme) => ({
  image: {
    height: 200,
    backgroundPosition: "center",
  },
  description: {
    paddingTop: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    color: theme.palette.grey[700],
  },
}));

// this is a custom component for the acquisition form
// it is a card with the satellite data and a button to choose the satellite
const AlgorithmCard = ({ name, image, description, onChoose, enabled }) => {
  // use the translation function from react-i18next
  const [t] = useTranslation();
  // use the styles from the useStyles hook
  const classes = useStyles();
  // the date the satellite stopped working or today

  return (
    <Card className={classes.card} style={{height: 600, minHeight:600}}>
      <CardMedia
        className={classes.image}
        image={image}
        title={name}
        description={description}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Typography
          component="p"
          className={classes.cardDescription}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={onChoose} disabled={!enabled} >
          {t("forms.acquisition.1.card.choose")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default AlgorithmCard;
